/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import { Pagination, Table as BSTable, Select } from 'flowbite-react';
import { Filter } from './TableFilter';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

export default function PaginatedTable<T extends { [key: string]: any }>({
  table,
  dataCount,
  createChildTable,
  subRowProp,
  columnResizeMode,
  showFilters = false,
  allowWordWrap = false,
  showPagination = true,
  showFooter = false,
  striped = true,
  highlightRow = false,
  selectableRow = false,
}: {
  table: Table<T>;
  dataCount: number;
  createChildTable?: (data: any) => JSX.Element;
  subRowProp?: keyof T;
  columnResizeMode: string;
  showFilters?: boolean;
  allowWordWrap?: boolean;
  showPagination?: boolean;
  showFooter?: boolean;
  striped?: boolean;
  highlightRow?: boolean;
  selectableRow?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page: number) => {
    table.setPageIndex(page - 1);
    setCurrentPage(page);
  };
  function round(number: number) {
    return Math.ceil(number / 20);
  }
  return (
    <div>
      {/* <ScrollContainer
        mouseScroll={{ ignoreElements: 'input, button' }}
        style={{ width: '100%' }}
      > */}
      <BSTable
        {...{
          style: {
            position: 'relative',
            // minWidth: '100%',
            // position: 'sticky',
            // minWidth: '100%',
            // top: 0,
            // zIndex: 1,
          },
        }}
        // size='sm'
        striped={striped}
        // bordered
        // hover
      >
        <thead
          {...{
            style: {
              position: 'sticky',
              // minWidth: '100%',
              top: 0,
              // zIndex: 1,
            },
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      position: 'relative',
                      width: header.getSize(),
                      minWidth: '25px',
                      padding: '5px 7px',
                    },
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      style={
                        {
                          // width: header.getSize(),
                        }
                      }
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <FaSortUp style={{ marginLeft: '1rem' }}></FaSortUp>
                          ),
                          desc: (
                            <FaSortDown
                              style={{ marginLeft: '1rem' }}
                            ></FaSortDown>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {showFilters && header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                      ) : showFilters ? (
                        <div style={{ height: '31px' }} />
                      ) : null}
                    </div>
                  )}
                  <div
                    {...{
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      className: `resizer ${
                        header.column.getIsResizing() ? `isResizing` : ''
                      }`,
                      style: {
                        cursor: 'col-resize',
                        transform:
                          columnResizeMode === 'onEnd' &&
                          header.column.getIsResizing()
                            ? `translateX(${
                                table.getState().columnSizingInfo.deltaOffset
                              }px)`
                            : '',
                      },
                    }}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...{
            style: {
              // position: 'sticky',
              // position:'relative',
              overflow: 'visible',
            },
          }}
        >
          {table.getRowModel().rows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        {...{
                          style: {
                            maxWidth: cell.column.getSize(),
                            overflow: 'hidden',
                            whiteSpace: allowWordWrap ? 'normal' : 'nowrap',
                            textOverflow: allowWordWrap ? '' : 'ellipsis',
                            padding: '5px 7px',
                            backgroundColor:
                              row.getIsSelected() && highlightRow
                                ? 'orange'
                                : '',
                          },
                          onClick: () => {
                            if (selectableRow) {
                              row.toggleSelected();
                              // if(row.getIsSelected() === false) {
                              //   row.getIsSelected();
                              //   row.getIsSomeSelected();
                              //   row.getToggleSelectedHandler();
                              //   row.toggleSelected(true);
                              // }
                              // else {
                              //   row.toggleSelected(false);
                              // }
                            }
                          },
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {createChildTable && subRowProp && row.getIsExpanded() && (
                  <tr>
                    <td colSpan={table.getFlatHeaders().length}>
                      <div>
                        {createChildTable &&
                          subRowProp &&
                          createChildTable!(row.original[subRowProp!])}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
        {showFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </BSTable>
      <footer
        {...{
          style: {
            position: 'sticky',
            minWidth: '100%',
            bottom: 0,
            paddingTop: 2,
            backgroundColor: 'white',
            // zIndex: 1,
          },
        }}
      >
        {showPagination && table.getRowModel().rows.length > 0 && (
          // <div className='d-flex width: 100%; bottom: 0; max-height: 10; justify-content-center z-index: 2;'>
          <div className='flex overflow-x-auto sm:justify-center'>
            <Pagination
              currentPage={currentPage}
              totalPages={round(dataCount)}
              onPageChange={onPageChange}
            ></Pagination>
            {/* <Pagination>
              <Pagination onClick={() => table.setPageIndex(0)} />
              <Pagination.Prev
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              />
              <Pagination.Ellipsis />
              <Pagination.Item active>
                {table.getState().pagination.pageIndex + 1}
              </Pagination.Item>

              <Pagination.Ellipsis />
              <Pagination.Next
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              />
              <Pagination.Last
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              />
            </Pagination> */}
            {/* <span className='ms-3'>
              <Select
                id='main-pagination'
                aria-label='rows per page'
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                value={table.getState().pagination.pageSize}
              >
                <option value='10'>10 rows</option>
                <option value='25'>25 rows</option>
                <option value='50'>50 rows</option>
                <option value='100'>100 rows</option>
              </Select>
            </span>
            <span className='fw-bold ms-3 pt-2'>
              {table.getFilteredRowModel().flatRows.length ?? 'N/A'}
            </span> */}
          </div>
        )}
      </footer>
      {/* </ScrollContainer> */}
    </div>
  );
}
