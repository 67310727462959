import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import React from 'react';
import { Link } from 'react-router-dom';
import { HiEye, HiPencilAlt } from 'react-icons/hi';
import { Button, Tooltip } from 'flowbite-react';
import { DisplayAd } from '../../Models/DisplayAd';
import { displayDateOnly } from '../../Utils';

export default function MemberAdsList({
  data,
  setSelectedDisplayAd,
  setShowDisplayAdModal,
}: {
  data: DisplayAd[];
  setSelectedDisplayAd: React.Dispatch<React.SetStateAction<DisplayAd | null>>;
  setShowDisplayAdModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const columnHelper = createColumnHelper<DisplayAd>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Tooltip content='View Member'>
            <Link to={`/member/${info.row.original.memberId}`}>
              <Button type='button' className='buttonMain p-2' size={20}>
                <HiEye />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content='Edit Ad'>
            <Button
              onClick={() => {
                setSelectedDisplayAd(info.row.original);
                setShowDisplayAdModal(true);
              }}
              className='buttonMain p-2'
              size={20}
            >
              <HiPencilAlt />
            </Button>
          </Tooltip>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.member?.name, {
      id: 'memberId',
      header: 'Member',
      cell: (info) => (
        <i>{info.getValue() ? info.row.original.member?.name : 'EMDA'}</i>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: 'startDate',
      header: 'Start Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),

      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: 'endDate',
      header: 'End Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
