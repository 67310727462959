import { useAppSelector } from '../../Reducers/store';
import { useEffect, useState } from 'react';
import { UserLevelNames } from '../../Models/UserLevel';
import { Button, Tooltip } from 'flowbite-react';
import { HiOutlinePlus } from 'react-icons/hi';
import Loader from '../Common/Loader';
import { toast } from 'react-toastify';
import MemberAdsList from './MemberAdsList';
import { DisplayAd } from '../../Models/DisplayAd';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import MemberAdsAddEditModal from './MemberAdsAddEditModal';

export default function MemberAds() {
  const [showDisplayAdModal, setShowDisplayAdModal] = useState<boolean>(false);
  const [selectedDisplayAd, setSelectedDisplayAd] = useState<DisplayAd | null>(
    null
  );

  const [data, setData] = useState<DisplayAd[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!showDisplayAdModal) getDisplayAds();
  }, [showDisplayAdModal]);

  const getDisplayAds = () => {
    try {
      setIsLoading(true);
      return DisplayAdsApi.getDisplayAds()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleAddDisplayAdClick = () => {
    setShowDisplayAdModal(true);
  };

  return (
    <>
      <div className='float-right pr-6'>
        <Tooltip content='Add Ad'>
          <Button onClick={handleAddDisplayAdClick} className='buttonMain'>
            <HiOutlinePlus />
          </Button>
        </Tooltip>
      </div>
      <div className='w-full'>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <MemberAdsList
            data={data}
            setSelectedDisplayAd={setSelectedDisplayAd}
            setShowDisplayAdModal={setShowDisplayAdModal}
          />
        </div>
        <MemberAdsAddEditModal
          show={showDisplayAdModal}
          setShow={setShowDisplayAdModal}
          selectedDisplayAd={selectedDisplayAd}
        />
      </div>
    </>
  );
}
