import { Button, Modal, Select, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { useNavigate } from 'react-router-dom';
import UserApi from '../../Api/UserApi';
import { User } from '../../Models/User';
import { fetchUserLevels } from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import Editor from '../Common/Editor';

export default function AddUserModal({
  show,
  setShow,
  memberId,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  memberId: number | null;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userLevels } = useAppSelector((state) => state.commonFields);
  const { userModel } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [quillValue, setQuillValue] = useState('');
  const [formData, setFormData] = useState<User>({
    id: 0,
    memberId: memberId,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    birthdate: null,
    userLevelId: UserLevelNames.MemberUser,
    isPrimary: false,
    address: '',
    city: '',
    state: '',
    zip: '',
    bio: '',
    imageId: null,
    image: null,
    member: null,
    userLevel: null,
    userFavorites: [],
  });

  const getReferences = async () => {
    if (!userLevels.length) {
      await Promise.all([dispatch(fetchUserLevels())]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show === true) {
      getReferences();
    }
  }, [show]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    formData.bio = quillValue;
    try {
      setIsLoading(true);
      return UserApi.addUpdateUser(formData)
        .then((res) => {
          if (res.data.success) {
            toast.success('success');
            setIsLoading(false);
            setButtonDisabled(false);
            navigate(`/user/${res.data.affectedEntityIdentifier}`);
          } else {
            toast.error('Error: ' + res.data.message);
            setButtonDisabled(false);
          }
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };
  function onCloseModal() {
    setQuillValue('');
    setShow(false);
  }
  return (
    <Modal show={show} size='4xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='w-full'>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>Add User</h1>
              </div>
            </div>
          </div>

          <div>
            <div className='container mx-auto p-2'>
              <div className='md:flex no-wrap md:-mx-2 '>
                {/* <!-- Right Side --> */}
                <div className='w-full'>
                  {/* <!-- Profile tab --> */}
                  {/* <!-- About Section --> */}
                  <div className='bg-white'>
                    <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8'>
                      <span className='tracking-wide uppercase text-3xl'>
                        User Information
                      </span>
                    </div>
                    <hr className='bg-gray-300 h-[5px]'></hr>
                    <form onSubmit={onSubmit}>
                      <div className='text-gray-500'>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            {/* <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div> */}
                            <div className='font-bold content-center'>
                              Name:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-1/3'>
                              <TextInput
                                id='firstName'
                                name='firstName'
                                type='text'
                                placeholder='First Name...'
                                onChange={handleChangeInput}
                                value={formData.firstName ?? ''}
                                sizing='md'
                                required
                              />
                            </div>
                            <div className='w-1/3'>
                              <TextInput
                                id='lastName'
                                name='lastName'
                                type='text'
                                placeholder='Last Name...'
                                onChange={handleChangeInput}
                                value={formData.lastName ?? ''}
                                sizing='md'
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            {/* <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div> */}
                            <div className='font-bold content-center'>
                              Email:
                            </div>
                          </div>
                          <div className='w-1/3'>
                            <TextInput
                              id='emailAddress'
                              name='emailAddress'
                              type='text'
                              placeholder='Email Address...'
                              onChange={handleChangeInput}
                              value={formData.emailAddress ?? ''}
                              sizing='md'
                              required
                            />
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            {/* <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div> */}
                            <div className='font-bold content-center'>
                              Phone:
                            </div>
                          </div>
                          <div className='w-1/3'>
                            <TextInput
                              id='phoneNumber'
                              name='phoneNumber'
                              type='text'
                              placeholder='Phone...'
                              onChange={handleChangeInput}
                              value={formData.phoneNumber ?? ''}
                              sizing='md'
                            />
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            {/* <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div> */}
                            <div className='font-bold content-center'>
                              Address:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-1/3'>
                              <TextInput
                                id='address'
                                name='address'
                                type='text'
                                placeholder='Street...'
                                onChange={handleChangeInput}
                                value={formData.address ?? ''}
                                sizing='md'
                              />
                            </div>
                            <div className='w-2/3 flex'>
                              <div className='w-1/3'>
                                <TextInput
                                  id='city'
                                  name='city'
                                  type='text'
                                  placeholder='City...'
                                  onChange={handleChangeInput}
                                  value={formData.city ?? ''}
                                  sizing='md'
                                />
                              </div>
                              <div className='w-1/3'>
                                <TextInput
                                  id='state'
                                  name='state'
                                  type='text'
                                  placeholder='State/Province...'
                                  onChange={handleChangeInput}
                                  value={formData.state ?? ''}
                                  sizing='md'
                                />
                              </div>
                              <div className='w-1/3'>
                                <TextInput
                                  id='zip'
                                  name='zip'
                                  type='text'
                                  placeholder='Zip...'
                                  onChange={handleChangeInput}
                                  value={formData.zip ?? ''}
                                  sizing='md'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            {/* <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div> */}
                            <div className='font-bold content-center'>
                              User Level:
                            </div>
                          </div>
                          <div className='w-1/3'>
                            <Select
                              id='userLevelId'
                              name='userLevelId'
                              onChange={handleChangeSelect}
                              value={formData.userLevelId ?? 2}
                              sizing='md'
                            >
                              {userLevels
                                .filter((x) =>
                                  userModel?.userLevelId ===
                                  UserLevelNames.SuperAdmin
                                    ? true
                                    : x.id !== UserLevelNames.SuperAdmin
                                )
                                .map((userLevel) => (
                                  <option
                                    key={userLevel.id}
                                    value={userLevel.id}
                                  >
                                    {userLevel.name}
                                  </option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>Bio:</div>
                          </div>
                          <div>
                            {/* <Textarea
                              id='bio'
                              name='bio'
                              //   type='text'
                              placeholder='Bio...'
                              onChange={handleChangeTextArea}
                              value={formData.bio ?? ''}
                              //   sizing='md'
                              rows={4}
                            /> */}
                            <Editor
                              id='bio'
                              name='bio'
                              value={quillValue}
                              className='w-full'
                              changedValue={setQuillValue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-end pt-2'>
                        <Button
                          disabled={buttonDisabled}
                          type='submit'
                          className='buttonMain'
                        >
                          Save
                        </Button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- End of profile tab --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
