import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseModel } from '../Models/ResponseModel';
import { User } from '../Models/User';
import { Member } from '../Models/Member';
import { Favorites } from '../Models/Favorites';
import { ResetPasswordRequest } from '../Models/ResetPasswordRequest';

class UserApi {
  static getUser(id: string) {
    return axios.get<User>(`${API_URL}/api/user/${id}`, getAuthHeader());
  }
  static addUpdateUser(request: User) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/user`,
      request,
      getAuthHeader()
    );
  }
  static uploadUserImage(request: FormData) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/user/imageupload`,
      request,
      getAuthHeader()
    );
  }
  static getUserFavoritesMembers() {
    return axios.get<Member[]>(
      `${API_URL}/api/user/getUserFavoritesMembers`,
      getAuthHeader()
    );
  }
  static getUserFavoritesUsers() {
    return axios.get<User[]>(
      `${API_URL}/api/user/getUserFavoritesUsers`,
      getAuthHeader()
    );
  }
  static getUserFavorites() {
    return axios.get<Favorites[]>(
      `${API_URL}/api/user/getUserFavorites`,
      getAuthHeader()
    );
  }
  static updatePassword(userId: number, request: ResetPasswordRequest) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/user/updatepassword/${userId}`,
      request,
      getAuthHeader()
    );
  }
}

export default UserApi;
