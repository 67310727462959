import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { File } from '../Models/File';

class FilesApi {
  static getUserFiles(id: number) {
    return axios.get<File[]>(
      `${API_URL}/api/user/GetUserResources/${id}`,
      getAuthHeader()
    );
  }
  static getMemberFiles(id: number) {
    return axios.get<File[]>(
      `${API_URL}/api/member/GetMemberResources/${id}`,
      getAuthHeader()
    );
  }
  static getResourceFiles() {
    return axios.get<File[]>(
      `${API_URL}/api/common/GetResourceFiles`,
      getAuthHeader()
    );
  }
}

export default FilesApi;
