import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { LoginRequest } from '../Models/LoginRequest';
import { User } from '../Models/User';
import { RecoverPasswordRequest } from '../Models/RecoverPasswordRequest';
import { ResponseModel } from '../Models/ResponseModel';
import { ResetPasswordRequest } from '../Models/ResetPasswordRequest';

class AuthApi {
  static login(data: LoginRequest) {
    return axios.post<string>(`${API_URL}/api/auth/login`, data);
  }
  static getCurrentUser() {
    return axios.get<User>(`${API_URL}/api/auth`, getAuthHeader());
  }
  static recoverPasswordRequest(request: RecoverPasswordRequest) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/auth/recoverpassword`,
      request,
      getAuthHeader()
    );
  }
  static resetPasswordRequest(guid: string, request: ResetPasswordRequest) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/auth/resetpassword/${guid}`,
      request,
      getAuthHeader()
    );
  }
}

export default AuthApi;
