import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import MemberApi from '../../Api/MemberApi';
import { useAppSelector } from '../../Reducers/store';
import MemberListTable from '../Member/MemberListTable';
import { Member } from '../../Models/Member';
import { ApplicationStatus } from '../../Enums/ApplicationStatus';
import { Label, Radio } from 'flowbite-react';

export default function SuperDashboard() {
  const { userModel } = useAppSelector((state) => state.user);
  const [memberListData, setMemberListData] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationStatusId, setApplicationStatusId] = useState<number>(1);

  useEffect(() => {
    getMemberApplications();
  }, [userModel, applicationStatusId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setApplicationStatusId(Number.parseInt(value));
  };

  const getMemberApplications = () => {
    try {
      setIsLoading(true);
      return MemberApi.getMembersByStatus(applicationStatusId)
        .then((res) => {
          setMemberListData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('Error');
          console.error(err);
          setIsLoading(false);
        });
    } catch (error) {
      toast.error('Error');
      console.error(error);
      setIsLoading(false);
    }
  };
  return (
    <div className='border-2 border-rose-600 rounded-md'>
      <div className='flex'>
        <div className='flex gap-4 absolute p-4 border-2'>
          <div className='flex items-center gap-1'>
            <Radio
              id='reject'
              name='applicationStatus'
              value={ApplicationStatus.All}
              onChange={handleChange}
              checked={applicationStatusId === ApplicationStatus.All}
            />
            <Label htmlFor='reject'>All</Label>
          </div>
          <div className='flex items-center gap-1'>
            <Radio
              id='approve'
              name='applicationStatus'
              value={ApplicationStatus.New}
              onChange={handleChange}
              checked={applicationStatusId === ApplicationStatus.New}
            />
            <Label htmlFor='approve'>New</Label>
          </div>
          <div className='flex items-center gap-1'>
            <Radio
              id='reject'
              name='applicationStatus'
              value={ApplicationStatus.Rejected}
              onChange={handleChange}
              checked={applicationStatusId === ApplicationStatus.Rejected}
            />
            <Label htmlFor='reject'>Rejected</Label>
          </div>
        </div>
        <div className='flex justify-center mx-auto'>
          <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
            <span className='tracking-wide uppercase text-3xl'>
              Applications
            </span>
          </div>
        </div>
      </div>
      <div className='w-full p-4'>
        {isLoading && <Loader />}
        <MemberListTable data={memberListData} />
      </div>
    </div>
  );
}
