/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouteError, Link, isRouteErrorResponse } from 'react-router-dom';
import { Button } from 'flowbite-react';
import React, { useEffect } from 'react';
import ErrorApi from '../../Api/ErrorApi';

export default function ErrorPage() {
  let errorMessage: string | null;
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    errorMessage = 'Unknown error';
  }

  const reportError = () => {
    if (errorMessage) {
      console.error(errorMessage);
      ErrorApi.reportError(errorMessage);
    }
  };

  useEffect(() => {
    reportError();
  }, [errorMessage]);
  return (
    <div className='bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center'>
      <div className='bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl'>
        <p className='text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300'>
          404
        </p>
        <p className='text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4'>
          Page Not Found
        </p>
        <p className='text-gray-500 mt-4 pb-4 border-b-2 text-center'>
          Sorry, the page you are looking for could not be found.
        </p>
        <div className='p-2'>
          <Link to='/'>
            <Button className='buttonMain'>Return Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
