import FileList from '../Common/FileList';
import { useState } from 'react';
import { Button, Tabs, Tooltip } from 'flowbite-react';
import AddFileModal from '../Common/AddFileModal';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import MemberSitePages from './MemberSitePages';
import { HiOutlinePlus } from 'react-icons/hi';
import MemberAds from './MemberAds';
import MemberInactiveList from './MemberInactiveList';

export default function MemberResources() {
  const { userModel } = useAppSelector((state) => state.user);
  const [showAddFile, setShowAddFile] = useState<boolean>(false);
  const [reloadFiles, setReloadFiles] = useState<boolean>(false);

  const handleAddFileClick = () => {
    setShowAddFile(true);
  };

  return (
    <div className='w-full'>
      <div className='w-full h-[74px] p-4'>
        <div className='mx-1 flex items-end'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>
              Member Resources
            </h1>
          </div>
        </div>
      </div>
      <Tabs style='fullWidth'>
        <Tabs.Item title='Pages'>
          <MemberSitePages />
        </Tabs.Item>
        <Tabs.Item title='Files'>
          <div className='float-right pr-6'>
            {userModel &&
              userModel.userLevelId === UserLevelNames.SuperAdmin && (
                <Tooltip content='Add File'>
                  <Button onClick={handleAddFileClick} className='buttonMain'>
                    <HiOutlinePlus />
                  </Button>
                </Tooltip>
              )}
          </div>
          <FileList reload={reloadFiles} setReload={setReloadFiles} />
          <AddFileModal
            show={showAddFile}
            setShow={setShowAddFile}
            memberId={0}
            userId={0}
            setReload={setReloadFiles}
          />
        </Tabs.Item>
        <Tabs.Item title='Ads'>
          <MemberAds />
        </Tabs.Item>
        <Tabs.Item title='Inactive Members'>
          <MemberInactiveList />
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
