import { Button, FileInput, Select, TextInput, Textarea } from 'flowbite-react';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import blankImage from '../../Assets/blank.png';
import {
  MdLocationPin,
  MdLocalPhone,
  MdEmail,
  MdInfo,
  MdSubscriptions,
  MdDateRange,
} from 'react-icons/md';
import {
  FaEarthAmericas,
  FaInstagram,
  FaLinkedin,
  FaSquareFacebook,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import { Member } from '../../Models/Member';
import {
  fetchIndustries,
  fetchSubscriptions,
  fetchTags,
} from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { formatDateInputs, isNumber } from '../../Utils';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../Models/SelectOption';

export default function NewApplicant() {
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { industries, subscriptions, tags } = useAppSelector(
    (state) => state.commonFields
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newApplicant, setNewApplicant] = useState<boolean>(true);
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState<number>(-1);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<Member>({
    id: 0,
    name: '',
    phone: '',
    website: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    quickbooksId: '',
    subscriptionId: Number.parseInt(subscriptionId ?? '0'),
    enrollmentDate: null,
    description: '',
    headline: '',
    emailAddress: '',
    facebookUrl: '',
    twitterUrl: '',
    logoUrl: '',
    imageId: null,
    isCurrentUserFavorite: false,
    industryId: null,
    establishedDate: '',
    emdasource: '',
    linesRepresented: '',
    topBrands: '',
    instagramUrl: '',
    youTubeUrl: '',
    country: '',
    isApproved: false,
    approvalDate: null,
    approvalNotes: '',
    approvalUserId: null,
    applicationStatus: 1,
    territory: '',
    linkedInUrl: null,

    image: null,
    industry: null,
    subscription: null,
    memberTags: [],
  });
  const [file, setFile] = useState<File | null>();
  const [memberTagIds, setMemberTagIds] = useState<SelectOption[] | null>();

  const tagsList = useMemo(() => {
    const list = tags.map((t) => ({
      label: t.name,
      value: t.id!,
    }));
    return [...list];
  }, [tags]);

  const getReferences = async () => {
    if (!industries.length || !subscriptions.length || !tags.length) {
      await Promise.all([
        dispatch(fetchIndustries()),
        dispatch(fetchSubscriptions()),
        dispatch(fetchTags()),
      ]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      formData.subscriptionId = Number.parseInt(subscriptionId);
      setSelectedSubscriptionId(formData.subscriptionId);
    }
  }, [subscriptionId]);

  useEffect(() => {
    getReferences();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTA = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    } else {
      setFile(null);
    }
  }

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    let memberId = 0;
    let memberUpdated = false;
    let imageUpdated = false;
    let tagsUpdated = false;
    try {
      setIsLoading(true);
      if (formData.industryId && formData.industryId.toString() === '-1') {
        formData.industryId = null;
      }
      if (formData.state === '-1') {
        formData.state = null;
      }
      if (formData.subscriptionId !== selectedSubscriptionId) {
        formData.subscriptionId = selectedSubscriptionId;
      }
      await MemberApi.addUpdateMember(formData)
        .then((res) => {
          if (res.data.success) {
            memberUpdated = true;
            memberId = Number(res.data.affectedEntityIdentifier);
          } else {
            toast.error('Unexpected Error');
            setButtonDisabled(false);
          }
        })
        .catch((err) => {
          toast.error('Member Error: ' + err);
          setButtonDisabled(false);
        });
      if (file) {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('memberId', memberId.toString());
        await MemberApi.uploadMemberImage(fd)
          .then((res) => {
            if (res.data.success) {
              imageUpdated = true;
            }
          })
          .catch((err) => {
            toast.error('Image Error: ' + err);
          });
      } else {
        imageUpdated = true;
      }
      if (memberTagIds) {
        await MemberApi.updateMemberTags(memberId, memberTagIds)
          .then((res) => {
            if (res.data.success) {
              tagsUpdated = true;
            }
          })
          .catch((err) => {
            toast.error('Tags Error: ' + err);
          });
      } else {
        tagsUpdated = true;
      }
      if (memberUpdated && imageUpdated && tagsUpdated) {
        navigate('/memberapplication/thankyou');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const iconstyle = { color: '#5b9d30' };

  return (
    <div className='w-full'>
      <div className='w-full p-4'>
        <div className='mx-1 flex items-end justify-center'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>New Applicant</h1>
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto my-5 p-5'>
          <div className='md:flex no-wrap md:-mx-2 '>
            {/* <!-- Left Side --> */}
            <div className='w-full md:w-3/12 md:mx-2'>
              {/* <!-- Profile Card --> */}
              <div className='h-56 sm:h-64 xl:h-80 2xl:h-96 overflow-hidden rounded-md'>
                <img src={blankImage} alt='...' />
              </div>
              <div>
                <FileInput
                  id='file-upload'
                  helperText='SVG, PNG, or JPG.'
                  onChange={handleImageChange}
                  disabled={buttonDisabled}
                  required
                />
              </div>
            </div>
            <div className='w-full md:w-9/12 mx-2 h-64'>
              <div className='bg-white p-3'>
                <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
                  {/* {!newApplicant && (
                    <span className='tracking-wide uppercase text-3xl'>
                      {memberData?.name}
                    </span>
                  )} */}
                </div>
                <hr className='bg-gray-300 h-[5px]'></hr>
                <form onSubmit={onSubmit}>
                  <div className='text-gray-500'>
                    {newApplicant && (
                      <div className='grid grid-cols-[20%_80%] text-xl'>
                        <div className='flex'>
                          <div className='px-1 content-center'></div>
                          <div className='font-bold content-center'>
                            Company Name:
                          </div>
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='name'
                            name='name'
                            type='text'
                            placeholder='Name...'
                            onChange={handleChange}
                            value={formData.name ?? ''}
                            sizing='md'
                            required
                          />
                        </div>
                      </div>
                    )}
                    {newApplicant && <hr className='bg-gray-300 h-[2px]'></hr>}
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdSubscriptions style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Subscription:
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <Select
                            id='subscriptionId'
                            name='subscriptionId'
                            onChange={handleChangeSelect}
                            value={selectedSubscriptionId}
                            sizing='md'
                            required
                          >
                            <option value='-1'>-select-</option>
                            {subscriptions.map((ind) => (
                              <option key={ind.id} value={ind.id}>
                                {ind.name +
                                  ' ($' +
                                  ind.annualFee +
                                  '/annually)'}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdInfo style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Industry:
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <Select
                            id='industryId'
                            name='industryId'
                            onChange={handleChangeSelect}
                            value={formData.industryId ?? 0}
                            sizing='md'
                            required
                          >
                            <option value='-1'>-select-</option>
                            {industries.map((ind) => (
                              <option key={ind.id} value={ind.id}>
                                {ind.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdDateRange style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Established Date:
                        </div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='establishedDate'
                          name='establishedDate'
                          type='date'
                          placeholder='Date...'
                          onChange={handleChange}
                          value={formatDateInputs(
                            formData.establishedDate ?? ''
                          )}
                          sizing='md'
                          pattern='\d{4}-\d{2}-\d{2}'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Address:</div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <TextInput
                            id='city'
                            name='city'
                            type='text'
                            placeholder='City...'
                            onChange={handleChange}
                            value={formData.city ?? ''}
                            sizing='md'
                            required
                          />
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='state'
                            name='state'
                            type='text'
                            placeholder='State/Province...'
                            onChange={handleChange}
                            value={formData.state ?? ''}
                            sizing='md'
                          />
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='country'
                            name='country'
                            type='text'
                            placeholder='Country...'
                            onChange={handleChange}
                            value={formData.country ?? ''}
                            sizing='md'
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Phone:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='phone'
                          name='phone'
                          type='text'
                          placeholder='Phone...'
                          onChange={handleChange}
                          value={formData.phone ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Website:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='website'
                          name='website'
                          type='text'
                          placeholder='Website...'
                          onChange={handleChange}
                          value={formData.website ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Email:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='emailAddress'
                          name='emailAddress'
                          type='text'
                          placeholder='Email Address...'
                          onChange={handleChange}
                          value={formData.emailAddress ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaLinkedin style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          LinkedIn:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='linkedInUrl'
                          name='linkedInUrl'
                          type='text'
                          placeholder='LinkedIn URL...'
                          onChange={handleChange}
                          value={formData.linkedInUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaSquareFacebook style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Facebook:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='facebookUrl'
                          name='facebookUrl'
                          type='text'
                          placeholder='Facebook URL...'
                          onChange={handleChange}
                          value={formData.facebookUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaTwitter style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Twitter:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='twitterUrl'
                          name='twitterUrl'
                          type='text'
                          placeholder='Twitter URL...'
                          onChange={handleChange}
                          value={formData.twitterUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaInstagram style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Instagram:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='instagramUrl'
                          name='instagramUrl'
                          type='text'
                          placeholder='Instagram URL...'
                          onChange={handleChange}
                          value={formData.instagramUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaYoutube style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Youtube:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='youTubeUrl'
                          name='youTubeUrl'
                          type='text'
                          placeholder='Youtube URL...'
                          onChange={handleChange}
                          value={formData.youTubeUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>Source:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='emdasource'
                          name='emdasource'
                          type='text'
                          placeholder='How did you hear about us?'
                          onChange={handleChange}
                          value={formData.emdasource ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          Lines Represented:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='linesRepresented'
                          name='linesRepresented'
                          type='text'
                          placeholder='Lines Represented...'
                          onChange={handleChange}
                          value={formData.linesRepresented ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          Top Brands:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='topBrands'
                          name='topBrands'
                          type='text'
                          placeholder='Top Brands...'
                          onChange={handleChange}
                          value={formData.topBrands ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          Territory:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='territory'
                          name='territory'
                          type='text'
                          placeholder='Territory...'
                          onChange={handleChange}
                          value={formData.territory ?? ''}
                          sizing='md'
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[20%_80%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Bio:</div>
                      </div>
                      <div>
                        <Textarea
                          id='description'
                          name='description'
                          //   type='text'
                          placeholder='Description...'
                          onChange={handleChangeTA}
                          value={formData.description ?? ''}
                          //   sizing='md'
                          rows={4}
                          required
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Tags:</div>
                      </div>
                      <div>
                        <CreatableSelect
                          name='name'
                          placeholder='Add Tags...'
                          isClearable
                          isMulti
                          value={memberTagIds}
                          onChange={(e) => {
                            if (e) {
                              setMemberTagIds(
                                e.map((object) =>
                                  Object.assign(object, {
                                    label: object.label,
                                    value:
                                      isNumber(object.value) &&
                                      object.label !== object.value.toString()
                                        ? object.value
                                        : 0,
                                  })
                                )
                              );
                            } else {
                              setMemberTagIds(null);
                            }
                          }}
                          options={tagsList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-end pt-2'>
                    <Button
                      disabled={buttonDisabled}
                      type='submit'
                      className='buttonMain'
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
