import {
  Button,
  Checkbox,
  FileInput,
  Modal,
  Select,
  TextInput,
  Textarea,
} from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { fetchMembers } from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { DisplayAd } from '../../Models/DisplayAd';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import blankImage from '../../Assets/blank.png';
import { formatDateInputs, formatNumbers, onlyNumberInput } from '../../Utils';

export default function MemberAdsAddEditModal({
  show,
  setShow,
  selectedDisplayAd,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDisplayAd: DisplayAd | null;
}) {
  const dispatch = useAppDispatch();
  const { members } = useAppSelector((state) => state.commonFields);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<DisplayAd>({
    id: 0,
    memberId: null,
    name: '',
    imageUrl: null,
    linkUrl: '',
    description: '',
    startDate: '',
    endDate: '',
    price: null,
    paid: null,
    dateCreated: null,

    displayAdEvents: [],
    member: null,
  });
  const [file, setFile] = useState<File | null>();

  useEffect(() => {
    handleSetInitialValues();
  }, [selectedDisplayAd]);

  const handleSetInitialValues = () => {
    const values = selectedDisplayAd
      ? {
          ...selectedDisplayAd,
        }
      : {
          id: 0,
          memberId: null,
          name: '',
          imageUrl: null,
          linkUrl: '',
          description: '',
          startDate: '',
          endDate: '',
          price: null,
          paid: null,
          dateCreated: null,

          displayAdEvents: [],
          member: null,
        };

    setFormData(values as DisplayAd);
  };

  const getReferences = async () => {
    if (!members.length) {
      await Promise.all([dispatch(fetchMembers())]);
    }
  };

  useEffect(() => {
    if (show === true) {
      getReferences();
    }
  }, [show]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    } else {
      setFile(null);
    }
  }

  const handleChangeCurrencyInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const formattedValue = formatNumbers(value);
    setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
  };

  const handleChangeCheckboxInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    try {
      setIsLoading(true);
      if (!formData.startDate) {
        formData.startDate = null;
      }
      if (!formData.endDate) {
        formData.endDate = null;
      }
      return DisplayAdsApi.addUpdateDisplayAd(formData)
        .then((res) => {
          if (res.data.success) {
            if (formData.id === 0) {
              formData.id = Number(res.data.affectedEntityIdentifier);
            }
            if (file) {
              const fd = new FormData();
              fd.append('file', file);
              fd.append('displayAdId', formData.id.toString());
              DisplayAdsApi.uploadDisplayAdImage(fd)
                .then((res) => {
                  setFile(null);
                  toast.success('Ad Saved');
                  setIsLoading(false);
                  setButtonDisabled(false);
                  onCloseModal();
                })
                .catch((err) => {
                  toast.error('Image Error: ' + err);
                });
            } else {
              toast.success('Ad Saved');
              setIsLoading(false);
              setButtonDisabled(false);
              // ReactGA.event({
              //   category: 'User',
              //   action: 'Saved Profile'
              // });
              onCloseModal();
            }
          } else {
            toast.error(res.data.message);
            setButtonDisabled(false);
          }
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };
  function onCloseModal() {
    setFormData({
      id: 0,
      memberId: null,
      name: '',
      imageUrl: null,
      linkUrl: '',
      description: '',
      startDate: '',
      endDate: '',
      price: null,
      paid: null,
      dateCreated: null,

      displayAdEvents: [],
      member: null,
    });
    setShow(false);
  }
  return (
    <Modal show={show} size='4xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='w-full h-[715px]'>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>Create Ad</h1>
              </div>
            </div>
          </div>

          <div>
            <div className='container mx-auto px-5'>
              <div className='no-wrap'>
                <div className='w-full mx-2 h-64'>
                  <div className='bg-white p-3'>
                    <hr className='bg-gray-300 h-[5px]'></hr>
                    <form id='editDisplayAd' onSubmit={onSubmit}>
                      <div className='text-gray-500'>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Member:
                            </div>
                          </div>
                          <div className='w-full'>
                            <Select
                              id='memberId'
                              name='memberId'
                              onChange={handleChangeSelect}
                              value={formData.memberId ?? ''}
                              sizing='md'
                              required
                            >
                              <option value={''}>(Select)</option>
                              {members.map((member) => (
                                <option key={member.id} value={member.id}>
                                  {member.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Start Date:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <TextInput
                                id='startDate'
                                name='startDate'
                                type='date'
                                placeholder='Start Date...'
                                onChange={handleChangeInput}
                                value={formatDateInputs(
                                  formData.startDate ?? ''
                                )}
                                sizing='md'
                                pattern='\d{4}-\d{2}-\d{2}'
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              End Date:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <TextInput
                                id='endDate'
                                name='endDate'
                                type='date'
                                placeholder='End Date...'
                                onChange={handleChangeInput}
                                value={formatDateInputs(formData.endDate ?? '')}
                                sizing='md'
                                pattern='\d{4}-\d{2}-\d{2}'
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Price:
                            </div>
                          </div>
                          <div className='grid grid-cols-[50%_50%]'>
                            <div className='w-full'>
                              <TextInput
                                id='price'
                                name='price'
                                type='text'
                                placeholder='0.00'
                                onChange={handleChangeCurrencyInput}
                                onKeyDown={(event) => {
                                  if (onlyNumberInput(event)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={formData.price ?? ''}
                                sizing='md'
                                addon='$'
                              />
                            </div>
                            <div className='w-full my-auto'>
                              <div className='grid grid-cols-[50%_50%] md:grid-cols-[25%_75%] text-xl'>
                                <div className='flex'>
                                  <div className='font-bold content-center m-auto'>
                                    Paid:
                                  </div>
                                </div>
                                <div className='flex'>
                                  <div className='w-full'>
                                    <Checkbox
                                      id='paid'
                                      name='paid'
                                      onChange={handleChangeCheckboxInput}
                                      checked={formData.paid ?? false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Name:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <TextInput
                                id='name'
                                name='name'
                                type='text'
                                placeholder='Name...'
                                onChange={handleChangeInput}
                                value={formData.name ?? ''}
                                sizing='md'
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Link:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <TextInput
                                id='linkUrl'
                                name='linkUrl'
                                type='text'
                                placeholder='Link URL...'
                                onChange={handleChangeInput}
                                value={formData.linkUrl ?? ''}
                                sizing='md'
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>

                        <div className='grid grid-cols-[25%_75%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Description:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <Textarea
                                id='description'
                                name='description'
                                placeholder='Description...'
                                onChange={handleChangeTextArea}
                                value={formData.description ?? ''}
                                rows={2}
                              />
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='w-full'>
                          <div className='flex flex-col w-full mx-auto object-contain h-56'>
                            <img
                              src={formData.imageUrl ?? blankImage}
                              alt={'image'}
                              className='object-contain h-56'
                            />
                          </div>
                          <div>
                            <FileInput
                              id='file-upload'
                              helperText='SVG, PNG, or JPG.'
                              onChange={handleImageChange}
                              disabled={buttonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className='flex justify-end mt-2'>
                      <Button
                        disabled={buttonDisabled}
                        type='submit'
                        className='buttonMain'
                        form='editDisplayAd'
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
