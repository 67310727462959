import axios from 'axios';
import { API_URL, getAuthHeader } from '.';

class UserFavoriteApi {
  static addUserFavoriteUser(userFavId: string) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/user/addUserFavoriteUser/${userFavId}`,
      {},
      getAuthHeader()
    );
  }
  static addUserFavoriteMember(favMemberId: string) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/user/addUserFavoriteMember/${favMemberId}`,
      {},
      getAuthHeader()
    );
  }
  static removeUserFavoriteUser(userFavId: string) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/user/removeUserFavoriteUser/${userFavId}`,
      {},
      getAuthHeader()
    );
  }
  static removeUserFavoriteMember(favMemberId: string) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/user/removeUserFavoriteMember/${favMemberId}`,
      {},
      getAuthHeader()
    );
  }
  static removeUserFavorite(favoriteId: string) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/user/removeUserFavorite/${favoriteId}`,
      {},
      getAuthHeader()
    );
  }
}

export default UserFavoriteApi;
