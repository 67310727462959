import { User } from '../../Models/User';
import React, { CSSProperties, useEffect, useState } from 'react';

import { Table as BSTable } from 'flowbite-react';

import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

// needed for table body level scope DnD setup
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UserListRequest } from '../../Models/UserListRequest';
import { Link, useParams } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import { HiUser } from 'react-icons/hi';
import { UserLevelNames } from '../../Models/UserLevel';
import { useAppSelector } from '../../Reducers/store';
import { toast } from 'react-toastify';

// Cell Component
const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    // Alternatively, you could set these attributes on the rows themselves
    <button {...attributes} {...listeners}>
      🟰
    </button>
  );
};

// Row Component
const DraggableRow = ({ row }: { row: Row<User> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id.toString(),
  });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };
  return (
    // connect row ref to dnd-kit, apply important styles
    <tr ref={setNodeRef} style={style}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} style={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

export default function MemberContactListTable() {
  const { id } = useParams();
  const { userModel } = useAppSelector((state) => state.user);
  const [formData, setFormData] = useState<UserListRequest>({
    firstName: '',
    lastName: '',
    emailAddress: '',
  });
  const [userContactListData, setContactUserListData] = useState<User[]>([]);
  const [updateOrder, setupdateOrder] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
  }, [id]);

  const getUsers = () => {
    if (id) {
      try {
        setIsLoading(true);
        return MemberApi.getMemberUserList(Number.parseInt(id), formData)
          .then((res) => {
            setContactUserListData(
              res.data.sort(function (a, b) {
                return (a.priority ?? 99) - (b.priority ?? 99);
              })
            );
            setIsLoading(false);
          })
          .catch((err) => {});
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      // Create a dedicated drag handle column. Alternatively, you could just set up dnd events on the rows themselves.
      {
        accessorFn: (row) => row.id,
        id: 'id',
        cell: (info) => (
          <div className='flex justify-center'>
            <Link to={`/user/${info.getValue()}`} className='buttonMain p-2'>
              <HiUser></HiUser>
            </Link>
          </div>
        ),
        header: '',
        size: 50,
      },
      {
        accessorKey: 'firstName',
        cell: (info) => info.getValue(),
        header: () => <span>First Name</span>,
      },
      {
        accessorFn: (row) => row.lastName,
        id: 'lastName',
        cell: (info) => info.getValue(),
        header: () => <span>Last Name</span>,
      },
      {
        accessorKey: 'emailAddress',
        header: () => 'Email',
      },
      {
        accessorKey: 'phoneNumber',
        header: () => <span>Phone</span>,
      },
      {
        id: 'drag-handle',
        header: 'Move',
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        size: 30,
      },
    ],
    []
  );
  //   const [data, setData] = React.useState(() => makeData(20))

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => userContactListData?.map(({ id }) => id.toString()),
    [userContactListData]
  );

  useEffect(() => {
    if (updateOrder && dataIds.length > 0) {
      updateOrderList();
      setupdateOrder(false);
    }
  }, [updateOrder]);

  function checkAccess() {
    if (userModel && id) {
      if (userModel.userLevelId === UserLevelNames.SuperAdmin) {
        return true;
      } else if (
        userModel.memberId === Number(id) &&
        userModel.userLevelId === UserLevelNames.MemberAdmin
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  const table = useReactTable({
    data: userContactListData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(), //required because row indexes will change
    initialState: { columnVisibility: { 'drag-handle': checkAccess() } },
  });

  const updateOrderList = async () => {
    if (id && dataIds) {
      await MemberApi.updateContactOrder(+id, dataIds)
        .then((res) => {
          if (!res.data.success) {
            toast.error('Error: ' + res.data.message);
          }
        })
        .catch((err) => {
          toast.error('Tags Error: ' + err);
        });
    }
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setContactUserListData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex); //this is just a splice util
      });
      setupdateOrder(true);
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className='w-full shadow-none pb-5'>
        <BSTable className=''>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => (
                <DraggableRow key={row.id} row={row} />
              ))}
            </SortableContext>
          </tbody>
        </BSTable>
      </div>
    </DndContext>
  );
}
