import { useAppSelector } from '../../Reducers/store';
import SuperDashboard from './SuperDashboard';
import { UserLevelNames } from '../../Models/UserLevel';
import UserFavorites from '../User/UserFavorites';
import RecentMembers from '../User/RecentMembers';
import ReactGA from 'react-ga4';
import { Carousel } from 'flowbite-react';
import { DisplayAd } from '../../Models/DisplayAd';
import { useEffect, useState } from 'react';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import Loader from '../Common/Loader';
import { DisplayAdEvent } from '../../Models/DisplayAdEvent';
export default function Dashboard() {
  ReactGA.send({ hitType: 'pageview', page: '/Dashboard', title: 'Dashboard' });
  const { userModel } = useAppSelector((state) => state.user);
  const [displayAds, setDisplayAds] = useState<DisplayAd[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMemberDisplayAds = () => {
    setIsLoading(true);
    DisplayAdsApi.getActiveDisplayAds()
      .then((res) => {
        setDisplayAds(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMemberDisplayAds();
  }, []);

  const handleBannerClick = (ad: DisplayAd) => {
    const clickEvent: DisplayAdEvent = {
      id: 0,
      displayAdId: ad.id,
      userId: userModel!.id,
      dateClicked: null,
      endUrl: ad.linkUrl,
      user: null,
    };
    DisplayAdsApi.addDisplayEventClick(clickEvent)
      .then((res) => {
        if (res.data) {
          window.open(ad.linkUrl!, '_blank');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='w-full'>
      {isLoading && <Loader />}
      <div className='w-full p-4'>
        <div className='mx-1 flex items-end'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl uppercase'>
              Dashboard
            </h1>
          </div>
        </div>
      </div>
      {userModel && displayAds && displayAds.length > 0 && (
        <div className='w-full mx-auto h-56 sm:h-64 xl:h-72 2xl:h-80 mb-5'>
          <Carousel
            slide={true}
            slideInterval={5000}
            indicators={false}
            leftControl={displayAds.length === 1 ? ' ' : ''}
            rightControl={displayAds.length === 1 ? ' ' : ''}
          >
            {displayAds.map((data) => (
              <div className='group flex flex-col w-full mx-auto object-contain h-56 sm:h-64 xl:h-72 2xl:h-80'>
                <img
                  key={data.id}
                  src={data.imageUrl!}
                  alt={data.name + '-image'}
                  className='object-contain h-56 sm:h-64 xl:h-72 2xl:h-80'
                />
                <div
                  onClick={() => handleBannerClick(data)}
                  className='hidden group-hover:block absolute content-end w-full h-full px-20 bg-black/50 text-white'
                >
                  <div className=''>{data.description}</div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <div className=''>
        {!userModel?.member &&
          userModel?.userLevelId === UserLevelNames.SuperAdmin && (
            <SuperDashboard />
          )}
        <div className='flex mt-10 gap-10'>
          <div className='w-1/2 p-2'>
            <UserFavorites />
          </div>
          <div className='w-1/2 p-2'>
            <RecentMembers />
          </div>
        </div>
      </div>
    </div>
  );
}
