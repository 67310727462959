import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { File } from '../../Models/File';
import { HiDownload } from 'react-icons/hi';

export default function DownloadFilesTable({ data }: { data: File[] }) {
  const columnHelper = createColumnHelper<File>();

  const columns = [
    columnHelper.accessor((row) => row.url, {
      id: 'download',
      header: '',
      cell: (info) => (
        <a href={info.row.original.url!} download={info.row.original.name}>
          <button
            className='p-2'
            disabled={info.row.original.url === null ? true : false}
            style={{ color: 'blue' }}
          >
            <HiDownload size={20} />
          </button>
        </a>
      ),
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      size: 25,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      header: 'Description',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.typeName, {
      id: 'typeName',
      header: 'Type',
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
