import 'devextreme/dist/css/dx.light.css';
import './App.css';
import { Outlet } from 'react-router-dom';
import TopNavigation from './Components/Navigation/TopNav';
import SideNav from './Components/Navigation/SideNav';
import ReactGA from "react-ga4";
ReactGA.initialize("G-MDSYKZ11YQ");

function App() {
  return (
    <>
      <TopNavigation></TopNavigation>
      <div className='flex'>
        <SideNav></SideNav>
        <Outlet></Outlet>
      </div>
    </>
  );
}

export default App;
