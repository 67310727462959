import { Button, FileInput, Select, TextInput, Textarea } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import blankImage from '../../Assets/blank.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserApi from '../../Api/UserApi';
import { User } from '../../Models/User';
import { fetchUserLevels } from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { UserLevel, UserLevelNames } from '../../Models/UserLevel';
import { fetchCurrentUser } from '../../Actions';
import UserUpdatePassword from './UserUpdatePassword';
import ReactGA from 'react-ga4';
import Editor from '../Common/Editor';
ReactGA.initialize('G-7CJ6RDK969');

export default function EditUserProfile() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userLevels } = useAppSelector((state) => state.commonFields);
  const { userModel } = useAppSelector((state) => state.user);
  const [userData, setUserData] = useState<User | null>();
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [quillValue, setQuillValue] = useState('');
  const [formData, setFormData] = useState<User>({
    id: 0,
    memberId: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    birthdate: null,
    userLevelId: 1,
    isPrimary: false,
    address: '',
    city: '',
    state: '',
    zip: '',
    bio: '',
    imageId: null,
    priority: null,
    image: null,
    member: null,
    userLevel: null,
    userFavorites: null,
  });
  const [file, setFile] = useState<File | null>();

  const getUser = () => {
    if (id) {
      setIsLoading(true);
      UserApi.getUser(id)
        .then((res) => {
          setUserData(res.data);
          ReactGA.send({
            hitType: 'pageview',
            page: '/page',
            title:
              'Edit User: ' + userModel?.firstName + ' ' + userModel?.lastName,
          });
          setQuillValue(res.data.bio ?? '');
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setUserData(null);
    }
  };

  const getReferences = async () => {
    if (!userLevels.length) {
      await Promise.all([dispatch(fetchUserLevels())]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  useEffect(() => {
    if (userData && userModel && id) {
      if (userModel.userLevelId !== UserLevelNames.SuperAdmin) {
        if (
          userModel.memberId !== userData.memberId ||
          (userModel.memberId === userData.memberId &&
            userModel.id !== Number(id) &&
            userModel.userLevelId !== UserLevelNames.MemberAdmin)
        ) {
          toast.error('You do not have access to this page');
          navigate(-1);
        }
      }
    }
    handleSetInitialValues();
  }, [userData]);

  useEffect(() => {
    getReferences();
  }, []);

  const handleSetInitialValues = () => {
    const values = userData
      ? {
          ...userData,
          userLevelId: userData.userLevelId ?? 1,
        }
      : {
          id: 0,
          memberId: 0,
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '',
          birthdate: null,
          userLevelId: 1,
          isPrimary: false,
          address: '',
          city: '',
          state: '',
          zip: '',
          bio: '',
        };

    setFormData(values as User);
    setQuillValue(values.bio ?? '');
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    } else {
      setFile(null);
    }
  }

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    formData.bio = quillValue;
    try {
      setIsLoading(true);
      return UserApi.addUpdateUser(formData)
        .then((res) => {
          if (file) {
            const fd = new FormData();
            fd.append('file', file);
            fd.append('userId', formData.id.toString());
            UserApi.uploadUserImage(fd)
              .then((res) => {
                toast.success('Image Uploaded');
                getUser();
                setFile(null);
                toast.success('User Saved');
                setIsLoading(false);
                setButtonDisabled(false);
                dispatch(fetchCurrentUser());
                setQuillValue('');
                navigate(`/user/${formData?.id}`);
              })
              .catch((err) => {
                toast.error('Image Error: ' + err);
              });
          } else {
            toast.success('User Saved');
            setIsLoading(false);
            setButtonDisabled(false);
            ReactGA.event({
              category: 'User',
              action: 'Saved Profile',
            });
            navigate(`/user/${formData?.id}`);
          }
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <div className='w-full'>
      <div className='w-full p-4'>
        <div className='mx-1 flex items-end justify-center'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>
              Edit User Information
            </h1>
          </div>
          <div className='absolute right-2'>
            <Link to={`/user/${userData?.id}`}>
              <Button className='buttonMain'>View Profile</Button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto my-5 p-5'>
          <div className='md:flex no-wrap md:-mx-2 '>
            {/* <!-- Left Side --> */}
            <div className='w-full md:w-3/12 md:mx-2'>
              {/* <!-- Profile Card --> */}
              <div className='h-56 sm:h-64 xl:h-80 2xl:h-96 overflow-hidden rounded-md'>
                <img src={userData?.image?.url ?? blankImage} alt='...' />
              </div>
              <div>
                <FileInput
                  id='file-upload'
                  helperText='SVG, PNG, or JPG.'
                  onChange={handleImageChange}
                  disabled={buttonDisabled}
                />
              </div>
              {/* <!-- End of profile card --> */}
            </div>
            {/* <!-- Right Side --> */}
            <div className='w-full md:w-9/12 mx-2 h-64'>
              {/* <!-- Profile tab --> */}
              {/* <!-- About Section --> */}
              <div className='bg-white p-3'>
                <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
                  <span className='tracking-wide uppercase text-3xl'>
                    {userData?.firstName} {userData?.lastName}
                  </span>
                </div>
                <hr className='bg-gray-300 h-[5px]'></hr>
                <form id='editUser' onSubmit={onSubmit}>
                  <div className='text-gray-500'>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div> */}
                        <div className='font-bold content-center'>Name:</div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <TextInput
                            id='firstName'
                            name='firstName'
                            type='text'
                            placeholder='First Name...'
                            onChange={handleChangeInput}
                            value={formData.firstName ?? ''}
                            sizing='md'
                          />
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='lastName'
                            name='lastName'
                            type='text'
                            placeholder='Last Name...'
                            onChange={handleChangeInput}
                            value={formData.lastName ?? ''}
                            sizing='md'
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div> */}
                        <div className='font-bold content-center'>Phone:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='phoneNumber'
                          name='phoneNumber'
                          type='text'
                          placeholder='Phone...'
                          onChange={handleChangeInput}
                          value={formData.phoneNumber ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div> */}
                        <div className='font-bold content-center'>Email:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='emailAddress'
                          name='emailAddress'
                          type='text'
                          placeholder='Email Address...'
                          onChange={handleChangeInput}
                          value={formData.emailAddress ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div> */}
                        <div className='font-bold content-center'>Address:</div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <TextInput
                            id='address'
                            name='address'
                            type='text'
                            placeholder='Street...'
                            onChange={handleChangeInput}
                            value={formData.address ?? ''}
                            sizing='md'
                          />
                        </div>
                        <div className='w-2/3 flex'>
                          <div className='w-1/3'>
                            <TextInput
                              id='city'
                              name='city'
                              type='text'
                              placeholder='City...'
                              onChange={handleChangeInput}
                              value={formData.city ?? ''}
                              sizing='md'
                            />
                          </div>
                          <div className='w-1/3'>
                            <TextInput
                              id='state'
                              name='state'
                              type='text'
                              placeholder='State/Province...'
                              onChange={handleChangeInput}
                              value={formData.state ?? ''}
                              sizing='md'
                            />
                          </div>
                          <div className='w-1/3'>
                            <TextInput
                              id='zip'
                              name='zip'
                              type='text'
                              placeholder='Zip...'
                              onChange={handleChangeInput}
                              value={formData.zip ?? ''}
                              sizing='md'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div> */}
                        <div className='font-bold content-center'>
                          User Level:
                        </div>
                      </div>
                      <div className='w-1/3'>
                        <Select
                          id='userLevelId'
                          name='userLevelId'
                          onChange={handleChangeSelect}
                          value={formData.userLevelId ?? 1}
                          sizing='md'
                          disabled={
                            userModel?.userLevelId !==
                              UserLevelNames.MemberAdmin &&
                            userModel?.userLevelId !== UserLevelNames.SuperAdmin
                          }
                        >
                          {userLevels
                            .filter((x) =>
                              userModel?.userLevelId ===
                              UserLevelNames.SuperAdmin
                                ? true
                                : x.id !== UserLevelNames.SuperAdmin
                            )
                            .map((userLevel) => (
                              <option key={userLevel.id} value={userLevel.id}>
                                {userLevel.name}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Bio:</div>
                      </div>
                      <div>
                        <Editor
                          id='description'
                          name='description'
                          value={quillValue}
                          className='w-full'
                          changedValue={setQuillValue}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  className={`flex ${
                    userData ? 'justify-between' : 'justify-end'
                  } pt-2`}
                >
                  {userData && (
                    <div>
                      <Button
                        className='buttonMain'
                        onClick={() => setShow(true)}
                      >
                        Update Password
                      </Button>
                      <UserUpdatePassword
                        show={show}
                        setShow={setShow}
                        user={userData}
                      />
                    </div>
                  )}
                  <Button
                    disabled={buttonDisabled}
                    type='submit'
                    className='buttonMain'
                    form='editUser'
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
