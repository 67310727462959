import axios from 'axios';
import { API_URL, getAuthHeader, getPDFAuthHeader } from '.';
import { Member } from '../Models/Member';
import { ResponseModel } from '../Models/ResponseModel';
import { UserListRequest } from '../Models/UserListRequest';
import { User } from '../Models/User';
import { SelectOption } from '../Models/SelectOption';
import { UniqueIdentifier } from '@dnd-kit/core';

class MemberApi {
  static getMember(id: string) {
    return axios.get<Member>(`${API_URL}/api/member/${id}`, getAuthHeader());
  }

  static getMembers() {
    return axios.get<Member[]>(
      `${API_URL}/api/member/GetMembers`,
      getAuthHeader()
    );
  }
  static getMembersInactive() {
    return axios.get<Member[]>(
      `${API_URL}/api/member/GetMembers/inactive`,
      getAuthHeader()
    );
  }
  static getMembersByStatus(statusId: number) {
    return axios.get<Member[]>(
      `${API_URL}/api/member/GetMembers/${statusId}`,
      getAuthHeader()
    );
  }

  static getMemberDirectory() {
    return axios.get(`${API_URL}/api/Reports/GetDirectory`, getPDFAuthHeader());
  }

  static addUpdateMember(request: Member) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/member`,
      request,
      getAuthHeader()
    );
  }

  static updateMemberApplication(request: number) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/member/updateapplication`,
      request,
      getAuthHeader()
    );
  }

  static getMemberUserList(memberId: number | 0, data: UserListRequest) {
    return axios.post<User[]>(
      `${API_URL}/api/member/${memberId}`,
      data,
      getAuthHeader()
    );
  }

  static uploadMemberImage(request: FormData) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/member/imageupload`,
      request,
      getAuthHeader()
    );
  }

  static updateMemberTags(memberId: number, request: SelectOption[]) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/member/${memberId}/updatetags`,
      request
    );
  }

  static sendMemberUserEmail(memberId: string) {
    return axios.get<ResponseModel>(
      `${API_URL}/api/member/${memberId}/sendUsersLoginInfo`,
      getAuthHeader()
    );
  }

  static getRecentMembers() {
    return axios.get<Member[]>(
      `${API_URL}/api/member/getrecentmembers`,
      getAuthHeader()
    );
  }

  static updateContactOrder(memberId: number, request: UniqueIdentifier[]) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/member/${memberId}/updatecontactorder`,
      request,
      getAuthHeader()
    );
  }
}

export default MemberApi;
