import { Button, Navbar } from 'flowbite-react';
import emdaLogo from '../../Assets/EMDA150.png';
import { Link } from 'react-router-dom';

export default function TopNavPublic() {
  return (
    <>
      <div className='w-full'>
        <Navbar fluid className='bg-mainBackground px-4'>
          <div className='w-[70px] lg:w-[250px] transition-width transition-slowest'>
            <Navbar.Brand href='/'>
              <img src={emdaLogo} className='mr-3 h-9' alt='Member Portal' />
              <div className='hidden lg:block xl:block'>
                <span className='self-center whitespace-nowrap text-xl font-bold dark:text-white'>
                  Member Portal
                </span>
              </div>
            </Navbar.Brand>
          </div>
          <div className='flex order-2 p-2'>
            <div>
              <Link to={`/login`}>
                <Button className='buttonMain'>Login</Button>
              </Link>
            </div>
            <div>
              <Link to={`/memberapplication/apply`}>
                <Button className='buttonMain'>Apply Now</Button>
              </Link>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
}
