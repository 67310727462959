import { Button, Tabs } from 'flowbite-react';
import { useEffect, useState } from 'react';
import blankImage from '../../Assets/blank.png';
import {
  MdLocationPin,
  MdLocalPhone,
  MdEmail,
  MdInfo,
  MdSubscriptions,
  MdDateRange,
  MdTag,
} from 'react-icons/md';
import { GrValidate } from 'react-icons/gr';
import {
  FaEarthAmericas,
  FaInstagram,
  FaLinkedin,
  FaSquareFacebook,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { RiAdvertisementFill } from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import UserFavoriteApi from '../../Api/UserFavoriteApi';
import { Member } from '../../Models/Member';
import {
  HiArchive,
  HiClipboardList,
  HiPencilAlt,
  HiLightBulb,
  HiOutlineStar,
  HiStar,
  HiFolderOpen,
  HiOutlineUserAdd,
  HiOutlineDocumentAdd,
  HiOutlineMail,
} from 'react-icons/hi';
import ContactList from './ContactList';
import InvoiceList from './InvoiceList';
import FileList from './FileList';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import AddUserModal from '../User/AddUserModal';
import { Tooltip } from 'flowbite-react';
import AddFileModal from '../Common/AddFileModal';
import { displayDateOnly } from '../../Utils';
import Search from '../Search/Search';
import { SearchResult } from '../../Models/SearchResult';
import SearchApi from '../../Api/SearchApi';
import { SearchRequest } from '../../Models/SearchRequest';
import { ApplicationStatus } from '../../Enums/ApplicationStatus';
import { toast } from 'react-toastify';
import DOMPurify from 'isomorphic-dompurify';
import MemberDisplayAdsListTable from './MemberDisplayAdsListTable';
import { DisplayAd } from '../../Models/DisplayAd';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import MemberDisplayAdsDataModal from './MemberDisplayAdsDataModal';
import MemberContactListTable from './MemberContactListTable';

export default function Profile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userModel } = useAppSelector((state) => state.user);
  const [memberData, setMemberData] = useState<Member | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [favorite, setFavorite] = useState<boolean | null | undefined>(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [showAddFile, setShowAddFile] = useState<boolean>(false);
  const [reloadFiles, setReloadFiles] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [displayAds, setDisplayAds] = useState<DisplayAd[]>([]);

  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);

  function searchTags(tagName: string) {
    const formData = { searchText: tagName, category: '' } as SearchRequest;
    try {
      setIsLoading(true);
      return SearchApi.search(formData)
        .then((res) => {
          setSearchData(res.data);
          setShowSearchResults(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const getMember = () => {
    if (id) {
      setFavorite(false);
      setIsLoading(true);
      MemberApi.getMember(id)
        .then((res) => {
          setMemberData(res.data);
          if (
            userModel?.userFavorites?.some(
              (x) => x.memberFavoriteId === res.data?.id
            )
          ) {
            setFavorite(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setMemberData(null);
    }
  };

  const getMemberDisplayAds = () => {
    if (id) {
      setIsLoading(true);
      DisplayAdsApi.getMemberDisplayAds(id)
        .then((res) => {
          setDisplayAds(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setDisplayAds([]);
    }
  };

  const handleAddUserClick = () => {
    setShowAddUser(true);
  };

  const handleEmailReminder = () => {
    setIsLoading(true);
    if (id) {
      MemberApi.sendMemberUserEmail(id)
        .then((res) => {
          toast.success('Email Sent Successfully');
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getMember();
    getMemberDisplayAds();
  }, [id]);

  useEffect(() => {
    setFavorite(memberData?.isCurrentUserFavorite);
  }, [memberData]);

  const iconstyle = { color: '#5b9d30' };

  function checkAccess() {
    if (userModel && id) {
      if (userModel.userLevelId === UserLevelNames.SuperAdmin) {
        return true;
      } else if (
        userModel.memberId === Number(id) &&
        userModel.userLevelId === UserLevelNames.MemberAdmin
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  function checkMemberAccess() {
    if (userModel && id) {
      if (userModel.userLevelId === UserLevelNames.SuperAdmin) {
        return true;
      } else if (userModel.memberId === Number(id)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  const addFavorite = () => {
    if (memberData) {
      if (favorite) {
        UserFavoriteApi.removeUserFavoriteMember(memberData?.id?.toString());
      } else {
        UserFavoriteApi.addUserFavoriteMember(memberData?.id?.toString());
      }
      setFavorite(!favorite);
    }
  };

  const handleAddFileClick = () => {
    setShowAddFile(true);
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full p-4'>
          <div className='mx-1 flex items-end'>
            <div className='flex flex-1'>
              <h1 className='text-gray-900 font-bold text-4xl'>
                Member Information
              </h1>
            </div>
            {checkAccess() && (
              <div className='right-2'>
                <Link to={`/edit/member/${memberData?.id}`}>
                  <Tooltip content='Edit Details'>
                    <Button className='buttonMain'>
                      <HiPencilAlt size={20}></HiPencilAlt>
                    </Button>
                  </Tooltip>
                </Link>
              </div>
            )}
            {checkAccess() && memberData && (
              <div className='right-2'>
                <Tooltip content='Add Contact/User'>
                  <Button onClick={handleAddUserClick} className='buttonMain'>
                    <HiOutlineUserAdd size={20} />
                  </Button>
                </Tooltip>
              </div>
            )}
            {checkAccess() && (
              <div className='right-2'>
                <Tooltip content='Add File/Document'>
                  <Button
                    className='buttonMain'
                    onClick={() => handleAddFileClick()}
                  >
                    <HiOutlineDocumentAdd size={20} />
                  </Button>
                </Tooltip>
              </div>
            )}
            {checkAccess() && memberData && (
              <div className='right-2'>
                <Tooltip content='Send Member Login Reminder'>
                  <Button onClick={handleEmailReminder} className='buttonMain'>
                    <HiOutlineMail size={20} />
                  </Button>
                </Tooltip>
              </div>
            )}
            <div className='right-2'>
              <Tooltip content='Toggle Favorite Status'>
                <Button className='buttonMain' onClick={() => addFavorite()}>
                  {favorite ? (
                    <HiStar size={20} />
                  ) : (
                    <HiOutlineStar size={20} />
                  )}
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div>
          <div className='container mx-auto p-2'>
            <div className='md:flex no-wrap md:-mx-2 '>
              {/* <!-- Left Side --> */}
              <div className='w-full md:w-2/12 md:mx-2'>
                {/* <!-- Profile Card --> */}
                <div className='h-72 overflow-hidden rounded-md align-middle content-center text-center'>
                  <img src={memberData?.image?.url ?? blankImage} alt='...' />
                </div>
                {/* <!-- End of profile card --> */}
              </div>
              {/* <!-- Right Side --> */}
              <div className='w-full md:w-10/12 mx-2'>
                {/* <!-- Profile tab --> */}
                {/* <!-- About Section --> */}
                <div className='bg-white p-3'>
                  <div className='flex items-end space-x-2 font-bold text-gray-500 py-2'>
                    <div className='left w-1/2'>
                      <span className='uppercase text-3xl'>
                        {memberData?.name}
                      </span>
                    </div>
                    <div className='flex w-full justify-end gap-2'>
                      {memberData?.linkedInUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.linkedInUrl.startsWith('https://')
                                ? memberData?.linkedInUrl
                                : 'https://' + memberData?.linkedInUrl
                            }
                          >
                            <FaLinkedin style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.facebookUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.facebookUrl.startsWith('https://')
                                ? memberData?.facebookUrl
                                : 'https://' + memberData?.facebookUrl
                            }
                          >
                            <FaSquareFacebook style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.instagramUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.instagramUrl.startsWith('https://')
                                ? memberData?.instagramUrl
                                : 'https://' + memberData?.instagramUrl
                            }
                          >
                            <FaInstagram style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.twitterUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.twitterUrl.startsWith('https://')
                                ? memberData?.twitterUrl
                                : 'https://' + memberData?.twitterUrl
                            }
                          >
                            <FaTwitter style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.youTubeUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.youTubeUrl.startsWith('https://')
                                ? memberData?.youTubeUrl
                                : 'https://' + memberData?.youTubeUrl
                            }
                          >
                            <FaYoutube style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[5px]'></hr>
                  <div className='text-gray-500'>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdSubscriptions style={iconstyle} />
                        </div>
                        <div className='font-bold'>Subscription:</div>
                      </div>
                      <div className='flex pl-1'>
                        {memberData?.subscription?.name}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div>
                        <div className='font-bold'>Address:</div>
                      </div>
                      <div className='pl-1'>
                        {memberData?.city}, {memberData?.state}{' '}
                        {memberData?.country}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div>
                        <div className='font-bold'>Phone:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {memberData?.phone}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div>
                        <div className='font-bold'>Website:</div>
                      </div>
                      <div className='pl-1 text-blue-600'>
                        {memberData?.website && (
                          <a
                            target='_blank'
                            href={
                              memberData?.website.startsWith('https://') ||
                              memberData?.website.startsWith('http://')
                                ? memberData?.website
                                : 'https://' + memberData?.website
                            }
                          >
                            {memberData?.website}
                          </a>
                        )}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div>
                        <div className='font-bold'>Email:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {memberData?.emailAddress}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex my-auto'>
                        <div className='px-1 content-center'>
                          <MdTag style={iconstyle} />
                        </div>
                        <div className='font-bold'>Tags:</div>
                      </div>
                      <div className='pl-1'>
                        <div className='flex flex-wrap gap-1'>
                          {memberData?.memberTags?.map((tag) => (
                            <button
                              key={tag.id}
                              className='border border-1 px-2 rounded-md hover:bg-gray-400 hover:text-white text-sm'
                              onClick={() => searchTags(tag.tag!.name!)}
                            >
                              {tag.tag?.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End of about section --> */}
                {/* <!-- End of profile tab --> */}
              </div>
            </div>
          </div>
        </div>

        {memberData && (
          <AddUserModal
            show={showAddUser}
            setShow={setShowAddUser}
            memberId={memberData.id}
          ></AddUserModal>
        )}
        <div className='w-full p-3'>
          <Tabs aria-label='Tabs with underline' style='underline'>
            <Tabs.Item
              active
              title='Info'
              icon={HiLightBulb}
              className='font-bold'
            >
              <div className='p-3'>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Description:</div>
                  </div>
                  <div className='pl-1 whitespace-pre-line'>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            memberData?.description ?? ''
                          ),
                        }}
                      />
                    }
                    {/* {memberData?.description} */}
                  </div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Territory:</div>
                  </div>
                  <div className='pl-1'>{memberData?.territory}</div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>EMDA Source:</div>
                  </div>
                  <div className='pl-1'>{memberData?.emdasource}</div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Lines Represented:</div>
                  </div>
                  <div className='pl-1'>{memberData?.linesRepresented}</div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Top Brands:</div>
                  </div>
                  <div className='pl-1'>{memberData?.topBrands}</div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Enrollment Date:</div>
                  </div>
                  <div className='pl-1'>
                    {memberData?.enrollmentDate
                      ? displayDateOnly(new Date(memberData?.enrollmentDate))
                      : ''}
                  </div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Industry:</div>
                  </div>
                  <div className='pl-1'>{memberData?.industry?.name}</div>
                </div>
                <div className='grid grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Established Date:</div>
                  </div>
                  <div className='pl-1'>
                    {memberData?.establishedDate
                      ? displayDateOnly(new Date(memberData?.establishedDate))
                      : ''}
                  </div>
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item
              title='Contacts'
              icon={HiClipboardList}
              className='font-bold'
            >
              <MemberContactListTable />
            </Tabs.Item>
            {checkMemberAccess() && (
              <Tabs.Item
                title='Invoices'
                icon={HiArchive}
                className='font-bold'
              >
                <InvoiceList />
              </Tabs.Item>
            )}
            <Tabs.Item
              title='Documents'
              icon={HiFolderOpen}
              className='font-bold'
            >
              <FileList reload={reloadFiles} setReload={setReloadFiles} />
            </Tabs.Item>
            {memberData && displayAds && displayAds.length > 0 && (
              <Tabs.Item
                title='Ads'
                icon={RiAdvertisementFill}
                className='font-bold'
              >
                <MemberDisplayAdsListTable data={displayAds} />
              </Tabs.Item>
            )}
          </Tabs>
        </div>
        {id && checkAccess() && (
          <AddFileModal
            show={showAddFile}
            setShow={setShowAddFile}
            memberId={Number.parseInt(id)}
            userId={0}
            setReload={setReloadFiles}
          />
        )}
      </div>
      <Search
        show={showSearchResults}
        setShow={setShowSearchResults}
        initialSearchData={searchData}
      />
    </>
  );
}
