import { Button, Modal, Select, TextInput } from 'flowbite-react';
import { SitePage } from '../../Models/SitePage';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { fetchPageTypes } from '../../Actions/CommonFields';
import { toast } from 'react-toastify';
import SitePageApi from '../../Api/SitePageApi';
import Loader from '../Common/Loader';
import Editor from '../Common/Editor';

export default function EditSitePage({
  selectedSitePage,
  show,
  setShow,
  setSelectedSitePage,
}: {
  selectedSitePage: number | null;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSitePage: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const dispatch = useAppDispatch();
  const { pageTypes } = useAppSelector((state) => state.commonFields);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quillValue, setQuillValue] = useState('');
  const [formData, setFormData] = useState<SitePage>({
    id: 0,
    pageTypeId: 3,
    title: '',
    header: '',
    description: '',
    metaData: '',
    pageType: null,
  });


  const getReferences = async () => {
    if (!pageTypes.length) {
      await Promise.all([dispatch(fetchPageTypes())]);
    }
  };

  useEffect(() => {
    getReferences();
  }, []);

  useEffect(() => {
    UpdateFormData();
  }, [selectedSitePage]);

  const UpdateFormData = () => {
    try {
      if (selectedSitePage) {
        SitePageApi.getSitePage(selectedSitePage?.toString())
          .then((res) => {
            if (res.data !== null) {
              setFormData(res.data);
            }
            if (res.data?.description) {
              setQuillValue(
                res.data.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
              );
            }
          })
          .catch((err) => {
            toast.error('Error: ' + err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    formData.description = quillValue;
    try {
      return SitePageApi.addUpdateSitePage(formData)
        .then((res) => {
          toast.success('success');
          setIsLoading(false);
          setButtonDisabled(false);
          onCloseModal();
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  function onCloseModal() {
    const values = {
      id: 0,
      pageTypeId: 3,
      title: '',
      header: '',
      description: '',
      metaData: '',
      pageType: null,
    };

    setFormData(values as SitePage);
    setShow(false);
    setQuillValue('');
    setSelectedSitePage(null);
  }
  return (
    <Modal show={show} size='4xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='w-full'>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>
                  Add/Edit Page
                </h1>
              </div>
            </div>
          </div>

          <form onSubmit={onSubmit}>
            <div className='text-gray-500'>
              <div className='grid grid-cols-[20%_80%] text-xl'>
                <div className='flex'>
                  <div className='font-bold content-center'>Title:</div>
                </div>
                <div className='flex'>
                  <div className='w-2/3'>
                    <TextInput
                      id='title'
                      name='title'
                      type='text'
                      placeholder='Title...'
                      onChange={handleChangeInput}
                      value={formData.title ?? ''}
                      sizing='md'
                    />
                  </div>
                </div>
              </div>
              <hr className='bg-gray-300 h-[2px]'></hr>
              <div className='grid grid-cols-[20%_80%] text-xl'>
                <div className='flex'>
                  <div className='font-bold content-center'>Header:</div>
                </div>
                <div className='w-2/3'>
                  <TextInput
                    id='header'
                    name='header'
                    type='text'
                    placeholder='Header...'
                    onChange={handleChangeInput}
                    value={formData.header ?? ''}
                    sizing='md'
                  />
                </div>
              </div>
              <hr className='bg-gray-300 h-[2px]'></hr>
              <div className='grid grid-cols-[20%_80%] text-xl'>
                <div className='flex'>
                  <div className='font-bold content-center'>HTML:</div>
                </div>
                <div className=''>
          <Editor
            id='description'
            name='description'
            value={quillValue}
            className='w-full'
            changedValue={setQuillValue}
          />
                </div>
              </div>
              <hr className='bg-gray-300 h-[2px]'></hr>
              <div className='grid grid-cols-[20%_80%] text-xl'>
                <div className='flex'>
                  <div className='font-bold content-center'>Meta Data:</div>
                </div>
                <div className=''>
                  <TextInput
                    id='metaData'
                    name='metaData'
                    type='text'
                    placeholder='MetaData...'
                    onChange={handleChangeInput}
                    value={formData.metaData ?? ''}
                    sizing='md'
                  />
                </div>
              </div>
              <hr className='bg-gray-300 h-[2px]'></hr>
              <div className='grid grid-cols-[20%_80%] text-xl'>
                <div className='flex'>
                  <div className='font-bold content-center'>Page Type:</div>
                </div>
                <div className='w-1/3'>
                  <Select
                    id='pageTypeId'
                    name='pageTypeId'
                    onChange={handleChangeSelect}
                    value={formData.pageTypeId ?? 3}
                    sizing='md'
                  >
                    {pageTypes.map((pageTypes) => (
                      <option key={pageTypes.id} value={pageTypes.id}>
                        {pageTypes.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className='flex justify-end pt-2'>
              <Button
                disabled={buttonDisabled}
                type='submit'
                className='buttonMain'
              >
                Save
              </Button>
            </div>
          </form>
        </div>
        {isLoading && <Loader />}
      </Modal.Body>
    </Modal>
  );
}
