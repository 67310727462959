import {
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  TextInput,
} from 'flowbite-react';
import { useState } from 'react';
import { LoginRequest } from '../../Models/LoginRequest';
import AuthApi from '../../Api/AuthApi';
import { jwtDecode } from 'jwt-decode';
import { DecodedToken } from '../../Models/DecodedToken';
import { useAppDispatch } from '../../Reducers/store';
import { fetchCurrentUser, setUserFromToken } from '../../Actions';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import emdaLogo from '../../Assets/EMDA150.png';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginRequest>({
    email: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setShowError(false);
      setIsLoading(true);
      return AuthApi.login(formData)
        .then((res) => {
          localStorage.setItem('EMDAToken', res.data);
          const decoded: DecodedToken = jwtDecode(res.data);
          dispatch(
            setUserFromToken({
              email:
                decoded[
                  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
                ],
              exp: decoded.exp,
            })
          );
          dispatch(fetchCurrentUser());
          toast.success('Success!');
          setIsLoading(false);
          navigate('/');
        })
        .catch((err) => {
          setShowError(true);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <div className='w-full p-4'>
        {isLoading && <Loader />}
        {/* <Loader></Loader> */}
      </div>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0'>
        <a
          href='/'
          className='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'
        >
          <img
            className='h-20 mr-2'
            src={emdaLogo}
            width='100'
            alt='logo'
          ></img>{' '}
          Member Portal
        </a>
        <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
          <div className='px-6 pt-6 space-y-4 md:space-y-6'>
            <div className='flex flex-col items-center justify-center'>
              <h1 className='text-xl font-bold text-gray-900 md:text-2xl dark:text-white'>
                Sign in to your account
              </h1>
            </div>
            <form className='flex max-w-md flex-col gap-4' onSubmit={onSubmit}>
              <div>
                <div className='mb-2 block'>
                  <Label htmlFor='email' value='Your email' />
                </div>
                <TextInput
                  id='email'
                  name='email'
                  type='email'
                  placeholder='your@email.com'
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div>
                <div className='mb-2 block'>
                  <Label htmlFor='pass' value='Your password' />
                </div>
                <TextInput
                  id='pass'
                  name='password'
                  type='password'
                  onChange={handleChange}
                  value={formData.password}
                  required
                />
              </div>
              {showError && (
                <Alert color='failure'>Authorization Failed!</Alert>
              )}
              <button
                type='submit'
                className='focus:outline-none text-white bg-mainButton hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2'
              >
                Submit
              </button>
            </form>
          </div>
          <div className='text-right p-2'>
            <Link
              to='/forgotpassword'
              className='hover:underline'
              style={{ color: 'blue' }}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
