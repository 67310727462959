import axios from 'axios';
import { API_URL, getAuthHeader } from '.';

const config = { headers: { 'Content-Type': 'application/json' } };

class ErrorApi {
  static reportError(message: string) {
    return axios.post<void>(`${API_URL}/api/error`, message, config);
  }
}

export default ErrorApi;
