import './App.css';
import { Outlet } from 'react-router-dom';
import TopNavPublic from './Components/Navigation/TopNavPublic';

function AppPublic() {
  return (
    <>
      <TopNavPublic></TopNavPublic>
      <div className=''>
        <Outlet></Outlet>
      </div>
    </>
  );
}

export default AppPublic;
