import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { DecodedToken } from '../../Models/DecodedToken';
import { fetchCurrentUser, setUserFromToken } from '../../Actions';
import { isAfter, toDate } from 'date-fns';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';

export default function RequireAuth({
  children,
  adminOnly,
}: {
  children: JSX.Element;
  adminOnly?: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authenticated, userModel, fetchingUser } = useAppSelector(
    (state) => state.user
  );

  // useEffect(() => {
  //   hasSiteFunctionAvailable();
  // }, [fetchingUser]);

  const handleTokenAfterRefresh = async () => {
    const token = localStorage.getItem('EMDAToken');
    if (token) {
      const decoded: DecodedToken = jwtDecode(token);
      const now = new Date();
      dispatch(
        setUserFromToken({
          email:
            decoded[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
            ],
          exp: decoded.exp,
        })
      );
      const isValid = isAfter(toDate(decoded.exp * 1000), now);
      if (isValid) {
        await dispatch(fetchCurrentUser());
        return isValid;
      }
      return false;
    }
    return false;
  };

  // const hasSiteFunctionAvailable = async () => {
  //   if (!fetchingUser) {
  //     if (!userModel) {
  //       const valid = await handleTokenAfterRefresh();
  //       if (!valid) {
  //         navigate(-1);
  //         return;
  //       }
  //     }

  //     //   if (siteFunction) {
  //     //     const val = userModel?.userSiteFunctions?.some(
  //     //       (f) => f.siteFunctionId === siteFunction && f.enabled
  //     //     );
  //     //     if (!val) {
  //     //       toast.error(
  //     //         `User does not have permission to access "${SiteFunctionsEnum[siteFunction]}"`
  //     //       );
  //     //       navigate(-1);
  //     //     }
  //     //   }
  //     //   if (claimTypeAccess) {
  //     //     const val = userModel?.userClaimTypeAccesses?.some(
  //     //       (f) => f.claimTypeId === claimTypeAccess
  //     //     );
  //     //     if (!val) {
  //     //       toast.error(
  //     //         `User does not have permission to access "${ClaimTypesString[claimTypeAccess]}"`
  //     //       );
  //     //       navigate(-1);
  //     //     }
  //     //   }
  //   }
  // };

  if (fetchingUser) {
    return <Loader />;
  }

  if (
    !localStorage.getItem('EMDAToken') ||
    (!authenticated && !handleTokenAfterRefresh() && !fetchingUser) ||
    !userModel
  ) {
    return <Navigate to='/login' replace />;
  }

  return children;
}
