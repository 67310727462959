import {
  Button,
  Carousel,
  Datepicker,
  FileInput,
  Label,
  Radio,
  Select,
  TextInput,
  Textarea,
} from 'flowbite-react';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import blankImage from '../../Assets/blank.png';
import {
  MdLocationPin,
  MdLocalPhone,
  MdEmail,
  MdInfo,
  MdSubscriptions,
  MdDateRange,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  FaEarthAmericas,
  FaInstagram,
  FaLinkedin,
  FaSquareFacebook,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import { Member } from '../../Models/Member';
import {
  fetchIndustries,
  fetchSubscriptions,
  fetchTags,
} from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { formatDateInputs, isNumber } from '../../Utils';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../Models/SelectOption';
import { ApplicationStatus } from '../../Enums/ApplicationStatus';
import ReactGA from 'react-ga4';
import Editor from '../Common/Editor';

export default function EditMemberProfile() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { industries, subscriptions, tags } = useAppSelector(
    (state) => state.commonFields
  );
  const { userModel } = useAppSelector((state) => state.user);
  const [memberData, setMemberData] = useState<Member | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [quillValue, setQuillValue] = useState('');
  const [formData, setFormData] = useState<Member>({
    id: 0,
    name: '',
    phone: '',
    website: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    quickbooksId: '',
    subscriptionId: 0,
    enrollmentDate: '',
    description: '',
    headline: '',
    emailAddress: '',
    facebookUrl: '',
    twitterUrl: '',
    logoUrl: '',
    imageId: null,
    isCurrentUserFavorite: false,
    industryId: null,
    establishedDate: '',
    emdasource: '',
    linesRepresented: '',
    topBrands: '',
    instagramUrl: '',
    youTubeUrl: '',
    country: '',
    isApproved: null,
    approvalDate: '',
    approvalNotes: '',
    approvalUserId: null,
    applicationStatus: null,
    territory: null,
    linkedInUrl: null,

    image: null,
    industry: null,
    subscription: null,
    memberTags: null,
  });
  const [file, setFile] = useState<File | null>();
  const [memberTagIds, setMemberTagIds] = useState<SelectOption[] | null>();

  const tagsList = useMemo(() => {
    const list = tags.map((t) => ({
      label: t.name,
      value: t.id!,
    }));
    return [...list];
  }, [tags]);

  const getMember = () => {
    if (id) {
      setIsLoading(true);
      MemberApi.getMember(id)
        .then((res) => {
          setMemberData(res.data);
          setQuillValue(res.data.description ?? '');
          ReactGA.send({
            hitType: 'pageview',
            page: '/page',
            title: 'Edit Member: ' + memberData?.name,
          });
          setMemberTagIds(
            res.data.memberTags?.map((object) =>
              Object.assign(object, {
                label: object.tag!.name,
                value: object.tag!.id,
              })
            )
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setMemberData(null);
    }
  };

  const getReferences = async () => {
    if (!industries.length || !subscriptions.length || !tags.length) {
      await Promise.all([
        dispatch(fetchIndustries()),
        dispatch(fetchSubscriptions()),
        dispatch(fetchTags()),
      ]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    getMember();
  }, [id]);

  useEffect(() => {
    if (userModel && id) {
      if (userModel.userLevelId !== UserLevelNames.SuperAdmin) {
        if (
          memberData?.applicationStatus === ApplicationStatus.New ||
          userModel.memberId !== Number(id) ||
          (userModel.memberId === Number(id) &&
            userModel.userLevelId !== UserLevelNames.MemberAdmin)
        ) {
          toast.error('You do not have access to this page');
          navigate(-1);
        }
      }
    }
    handleSetInitialValues();
  }, [memberData]);

  useEffect(() => {
    getReferences();
  }, []);

  const handleSetInitialValues = () => {
    const values = memberData
      ? {
          ...memberData,
        }
      : {
          id: 0,
          name: '',
          phone: '',
          website: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          quickbooksId: '',
          subscriptionId: 0,
          enrollmentDate: '',
          description: '',
          headline: '',
          emailAddress: '',
          industryId: null,
        };

    setFormData(values as Member);
    setQuillValue(values.description ?? '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTA = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    } else {
      setFile(null);
    }
  }

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    formData.description = quillValue;
    let memberUpdated = false;
    let imageUpdated = false;
    let tagsUpdated = false;
    try {
      setIsLoading(true);
      if (formData.industryId && formData.industryId.toString() === '-1') {
        formData.industryId = null;
      }
      if (formData.state === '-1') {
        formData.state = null;
      }
      await MemberApi.addUpdateMember(formData)
        .then((res) => {
          if (res.data.success) {
            memberUpdated = true;
          }
        })
        .catch((err) => {
          toast.error('Member Error: ' + err);
          setButtonDisabled(false);
        });
      if (file) {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('memberId', formData.id.toString());
        await MemberApi.uploadMemberImage(fd)
          .then((res) => {
            if (res.data.success) {
              imageUpdated = true;
            }
          })
          .catch((err) => {
            toast.error('Image Error: ' + err);
          });
      } else {
        imageUpdated = true;
      }
      if (memberTagIds) {
        await MemberApi.updateMemberTags(formData.id, memberTagIds)
          .then((res) => {
            if (res.data.success) {
              tagsUpdated = true;
            }
          })
          .catch((err) => {
            toast.error('Tags Error: ' + err);
          });
      } else {
        tagsUpdated = true;
      }
      setIsLoading(false);
      setButtonDisabled(false);
      if (memberUpdated && imageUpdated && tagsUpdated) {
        toast.success('Save Success');
        ReactGA.event({
          category: 'Member',
          action: 'Saved Profile',
        });
        setQuillValue('');
        navigate(`/member/${memberData?.id}`);
      } else {
        toast.error('Error while saving');
      }
      return;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const iconstyle = { color: '#5b9d30' };

  return (
    <div className='w-full'>
      <div className='w-full p-4'>
        <div className='mx-1 flex items-end justify-center'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>
              Member Information
            </h1>
          </div>
          <div className='absolute right-2 flex'>
            <Link to={`/member/${memberData?.id}`}>
              <Button className='buttonMain'>View Profile</Button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto my-5 p-5'>
          <div className='md:flex no-wrap md:-mx-2 '>
            {/* <!-- Left Side --> */}
            <div className='w-full md:w-3/12 md:mx-2'>
              {/* <!-- Profile Card --> */}
              <div className='h-56 sm:h-64 xl:h-80 2xl:h-96 overflow-hidden rounded-md align-middle content-center text-center'>
                <img src={memberData?.image?.url ?? blankImage} alt='...' />
              </div>
              <div>
                <FileInput
                  id='file-upload'
                  helperText='SVG, PNG, or JPG.'
                  onChange={handleImageChange}
                  disabled={buttonDisabled}
                />
              </div>
              {/* <!-- End of profile card --> */}
            </div>
            {/* <!-- Right Side --> */}
            <div className='w-full md:w-9/12 mx-2 h-64'>
              {/* <!-- Profile tab --> */}
              {/* <!-- About Section --> */}
              <div className='bg-white p-3'>
                <div className='flex items-end space-x-2 font-bold text-gray-500 py-2'>
                  <div className='left w-1/2'>
                    <span className='uppercase text-3xl'>
                      {memberData?.name}
                    </span>
                  </div>
                </div>
                <hr className='bg-gray-300 h-[5px]'></hr>
                <form onSubmit={onSubmit}>
                  <div className='text-gray-500'>
                    {memberData?.applicationStatus === ApplicationStatus.New &&
                      userModel &&
                      userModel.userLevelId === UserLevelNames.SuperAdmin && (
                        <div className='bg-yellow-200'>
                          <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                            <div className='flex'>
                              <div className='px-1 content-center'>
                                <MdSettingsApplications style={iconstyle} />
                              </div>
                              <div className='font-bold content-center'>
                                Application:
                              </div>
                            </div>
                            <div className='flex'>
                              <div className='w-1/3 flex gap-4'>
                                <div className='flex items-center gap-1'>
                                  <Radio
                                    id='approve'
                                    name='applicationStatus'
                                    value={3}
                                    onChange={handleChange}
                                  />
                                  <Label htmlFor='approve'>Approve</Label>
                                </div>
                                <div className='flex items-center gap-1'>
                                  <Radio
                                    id='reject'
                                    name='applicationStatus'
                                    value={2}
                                    onChange={handleChange}
                                  />
                                  <Label htmlFor='reject'>Reject</Label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className='bg-gray-300 h-[2px]'></hr>
                        </div>
                      )}
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Name:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='name'
                          name='name'
                          type='text'
                          placeholder='Name...'
                          onChange={handleChange}
                          value={formData.name ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdSubscriptions style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Subscription:
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <Select
                            id='subscriptionId'
                            name='subscriptionId'
                            onChange={handleChangeSelect}
                            value={formData.subscriptionId ?? 0}
                            sizing='md'
                            disabled={
                              userModel === null ||
                              !(
                                userModel?.userLevelId ===
                                UserLevelNames.SuperAdmin
                              )
                            }
                          >
                            <option value='-1'>-select-</option>
                            {subscriptions.map((ind) => (
                              <option key={ind.id} value={ind.id}>
                                {ind.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdInfo style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Industry:
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <Select
                            id='industryId'
                            name='industryId'
                            onChange={handleChangeSelect}
                            value={formData.industryId ?? 0}
                            sizing='md'
                          >
                            <option value='-1'>-select-</option>
                            {industries.map((ind) => (
                              <option key={ind.id} value={ind.id}>
                                {ind.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdDateRange style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Established Date:
                        </div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='establishedDate'
                          name='establishedDate'
                          type='date'
                          placeholder='Date...'
                          onChange={handleChange}
                          value={formatDateInputs(
                            formData.establishedDate ?? ''
                          )}
                          sizing='md'
                          pattern='\d{4}-\d{2}-\d{2}'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Address:</div>
                      </div>
                      <div className='flex'>
                        <div className='w-1/3'>
                          <TextInput
                            id='city'
                            name='city'
                            type='text'
                            placeholder='City...'
                            onChange={handleChange}
                            value={formData.city ?? ''}
                            sizing='md'
                          />
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='state'
                            name='state'
                            type='text'
                            placeholder='State/Province...'
                            onChange={handleChange}
                            value={formData.state ?? ''}
                            sizing='md'
                          />
                        </div>
                        <div className='w-1/3'>
                          <TextInput
                            id='country'
                            name='country'
                            type='text'
                            placeholder='Country...'
                            onChange={handleChange}
                            value={formData.country ?? ''}
                            sizing='md'
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Phone:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='phone'
                          name='phone'
                          type='text'
                          placeholder='Phone...'
                          onChange={handleChange}
                          value={formData.phone ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Website:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='website'
                          name='website'
                          type='text'
                          placeholder='Website...'
                          onChange={handleChange}
                          value={formData.website ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Email:</div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='emailAddress'
                          name='emailAddress'
                          type='text'
                          placeholder='Email Address...'
                          onChange={handleChange}
                          value={formData.emailAddress ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaLinkedin style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          LinkedIn:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='linkedInUrl'
                          name='linkedInUrl'
                          type='text'
                          placeholder='LinkedIn URL...'
                          onChange={handleChange}
                          value={formData.linkedInUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaSquareFacebook style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Facebook:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='facebookUrl'
                          name='facebookUrl'
                          type='text'
                          placeholder='Facebook URL...'
                          onChange={handleChange}
                          value={formData.facebookUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaTwitter style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Twitter:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='twitterUrl'
                          name='twitterUrl'
                          type='text'
                          placeholder='Twitter URL...'
                          onChange={handleChange}
                          value={formData.twitterUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaInstagram style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Instagram:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='instagramUrl'
                          name='instagramUrl'
                          type='text'
                          placeholder='Instagram URL...'
                          onChange={handleChange}
                          value={formData.instagramUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaYoutube style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Youtube:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='youTubeUrl'
                          name='youTubeUrl'
                          type='text'
                          placeholder='Youtube URL...'
                          onChange={handleChange}
                          value={formData.youTubeUrl ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          EMDA Source:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='emdasource'
                          name='emdasource'
                          type='text'
                          placeholder='EMDA Source...'
                          onChange={handleChange}
                          value={formData.emdasource ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdDateRange style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>
                          Enrollment Date:
                        </div>
                      </div>
                      <div className='w-1/3'>
                        <TextInput
                          id='enrollmentDate'
                          name='enrollmentDate'
                          type='date'
                          placeholder='Date...'
                          onChange={handleChange}
                          value={formatDateInputs(
                            formData.enrollmentDate ?? ''
                          )}
                          sizing='md'
                          pattern='\d{4}-\d{2}-\d{2}'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>Lines:</div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='linesRepresented'
                          name='linesRepresented'
                          type='text'
                          placeholder='Lines Represented...'
                          onChange={handleChange}
                          value={formData.linesRepresented ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          Top Brands:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='topBrands'
                          name='topBrands'
                          type='text'
                          placeholder='Top Brands...'
                          onChange={handleChange}
                          value={formData.topBrands ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          {/* <FaYoutube style={iconstyle} /> */}
                        </div>
                        <div className='font-bold content-center'>
                          Territory:
                        </div>
                      </div>
                      <div className='w-2/3'>
                        <TextInput
                          id='territory'
                          name='territory'
                          type='text'
                          placeholder='Territory...'
                          onChange={handleChange}
                          value={formData.territory ?? ''}
                          sizing='md'
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Bio:</div>
                      </div>
                      <div>
                        <Editor
                          id='description'
                          name='description'
                          value={quillValue}
                          className='w-full'
                          changedValue={setQuillValue}
                        />
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] text-xl'>
                      <div className='flex'>
                        <div className='font-bold content-center'>Tags:</div>
                      </div>
                      <div>
                        <CreatableSelect
                          name='name'
                          placeholder='Add Tags...'
                          isClearable
                          isMulti
                          value={memberTagIds}
                          onChange={(e) => {
                            if (e) {
                              setMemberTagIds(
                                e.map((object) =>
                                  Object.assign(object, {
                                    label: object.label,
                                    value:
                                      isNumber(object.value) &&
                                      object.label !== object.value.toString()
                                        ? object.value
                                        : 0,
                                  })
                                )
                              );
                            } else {
                              setMemberTagIds(null);
                            }
                          }}
                          // styles={{
                          //   control: (baseStyles: any, state: any) => ({
                          //     ...baseStyles,
                          //     borderColor: touched && error && 'red',
                          //   }),
                          // }}
                          options={tagsList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-end pt-2'>
                    <Button
                      disabled={buttonDisabled}
                      type='submit'
                      className='buttonMain'
                    >
                      Save
                    </Button>
                  </div>
                </form>
                {/* <button className='block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4'>
                  Show Full Information
                </button> */}
              </div>
              {/* <!-- End of about section --> */}
              {/* <!-- End of profile tab --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
