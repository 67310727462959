import { User } from '../../Models/User';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Link } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import { Member } from '../../Models/Member';
import React from 'react';
export default function MemberListTable({ data }: { data: Member[] }) {
  const columnHelper = createColumnHelper<Member>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Link to={`/member/${info.getValue()}`} className='buttonMain p-2'>
            <HiUser></HiUser>
          </Link>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.subscription?.name, {
      id: 'subscriptionId',
      header: 'Subscription',
      // cell: (info) => <i>{info.row.original.subscription?.name}</i>,
      // filterFn: 'includesString',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.website, {
      id: 'website',
      header: 'Website',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      header: 'Phone',
      cell: (info) => info.getValue() ?? '',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
