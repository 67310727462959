/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '.';
import CommonApi from '../Api/CommonApi';

export const fetchStates = createAsyncThunk(
  'FETCH_STATES',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getStates();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchUserLevels = createAsyncThunk(
  'FETCH_USER_LEVELS',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getUserLevels();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchPageTypes = createAsyncThunk(
  'FETCH_PAGE_TYPES',
  async (_, thunkApi) => {
    // const { pageTypes } = useAppSelector((state))
    try {
      const response = await CommonApi.getPageTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchIndustries = createAsyncThunk(
  'FETCH_INDUSTRIES',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getIndustries();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchFileTypes = createAsyncThunk(
  'FETCH_FILETYPES',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getFileTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchSubscriptions = createAsyncThunk(
  'FETCH_SUBSCRIPTIONS',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getSubscriptions();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchTags = createAsyncThunk('FETCH_TAGS', async (_, thunkApi) => {
  try {
    const response = await CommonApi.getTags();
    return response.data;
  } catch (error) {
    const { errorMessage } = handleError(error);
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const fetchMembers = createAsyncThunk(
  'FETCH_MEMBERS',
  async (_, thunkApi) => {
    try {
      const response = await CommonApi.getMembers();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
