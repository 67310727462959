import { Button, Modal, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { SearchRequest } from '../../Models/SearchRequest';
import SearchTable from './SearchTable';
import SearchApi from '../../Api/SearchApi';
import { SearchResult } from '../../Models/SearchResult';
import Loader from '../Common/Loader';

export default function Search({
  show,
  setShow,
  initialSearchData,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  initialSearchData: SearchResult[] | null;
}) {
  const [formData, setFormData] = useState<SearchRequest>({
    searchText: '',
    category: '',
    selectedTagIds: [],
  });
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (initialSearchData) {
      setSearchData(initialSearchData);
    }
  }, [initialSearchData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      return SearchApi.search(formData)
        .then((res) => {
          setSearchData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  function onCloseModal() {
    setShow(false);
  }
  return (
    <Modal show={show} size='3xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='flex justify-center text-3xl font-bold'>
          Member Search
        </div>
        <div className='w-full pt-4'>
          <div className='w-full mx-auto pb-3'>
            <form onSubmit={onSubmit} className='flex justify-center'>
              <TextInput
                id='searchText'
                name='searchText'
                type='text'
                placeholder='Search...'
                onChange={handleChange}
                value={formData.searchText}
                className='w-2/3 pr-1'
              />
              <Button type='submit' className='buttonMain'>
                Search
              </Button>
            </form>
          </div>
          <div className='w-full p-2'>
            {isLoading && <Loader />}
            <SearchTable data={searchData} setShow={setShow} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
