import { Button, Card } from 'flowbite-react';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { useNavigate, useParams } from 'react-router';
import { fetchSubscriptions } from '../../Actions/CommonFields';
import { useEffect } from 'react';

export default function MemberApplication() {
  const { subscriptions } = useAppSelector((state) => state.commonFields);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getReferences = async () => {
    if (!subscriptions.length) {
      await Promise.all([dispatch(fetchSubscriptions())]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    getReferences();
  }, []);

  const onSubmit = (subscriptionId: number) => {
    if (subscriptionId !== null || subscriptionId !== 0) {
      navigate(`/memberapplication/newapplicant/${subscriptionId}`);
    }
  };

  return (
    <>
      <div className='w-full flex justify-center p-5'>
        <h5 className='text-gray-900 font-bold text-2xl'>Member Application</h5>
      </div>
      <div className='flex items-center justify-center'>
        <div className=''>
          <div className='grid grid-cols-1 text-xl pt-2 ps-2'>
            <div className='flex p-3'>
              Please select your membership level below then proceed to fill out
              the Membership Application. We look forward to partnering with
              you.
            </div>
          </div>
          <hr className='bg-gray-300 h-[2px]'></hr>
          <div className='grid grid-cols-3 text-xl'>
            {subscriptions
              .filter((x) => x.annualFee !== null && x.annualFee > 0)
              .sort((a, b) => (a.annualFee ?? 0) - (b.annualFee ?? 0))
              .map((row) => (
                <div key={row.id} className=' pt-2 ps-2'>
                  <Card className='bg-mainBackground w-full  border-2 rounded-md  shadow-md text-center'>
                    <h5 className='mb-1 text-3xl font-bold'>{row.name}</h5>
                    <div className='flex items-baseline text-gray-900 dark:text-white justify-center pt-2'>
                      <span className='text-3xl font-semibold'>$</span>
                      <span className='text-5xl font-extrabold tracking-tight'>
                        {row.annualFee?.toString()}
                      </span>
                      <span className='ml-1 text-xl font-normal text-gray-500 dark:text-gray-400'>
                        /annually
                      </span>
                    </div>
                    <div className='flex justify-center pt-2'>
                      <Button
                        className='buttonMain'
                        onClick={() => {
                          onSubmit(row.id);
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Card>
                </div>
              ))}

            <div className='w-1/3'></div>
            <div className='w-1/3'></div>
          </div>
        </div>
      </div>
    </>
  );
}
