import { Label, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import AuthApi from '../../Api/AuthApi';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import emdaLogo from '../../Assets/EMDA150.png';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { ResetPasswordRequest } from '../../Models/ResetPasswordRequest';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { guid } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<ResetPasswordRequest>({
    email: '',
    password: '',
    passwordConfirm: '',
  });

  useEffect(() => {
    if (!guid) {
      navigate('/');
    }
  }, []);

  const handlePasswordReset = () => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    // setButtonDisabled(true);
    e.preventDefault();
    try {
      setIsLoading(true);
      return AuthApi.resetPasswordRequest(guid!, formData)
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.message);
            handlePasswordReset();
          } else {
            toast.error(res.data.message);
            setButtonDisabled(false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      setIsLoading(false);
      setButtonDisabled(false);
      console.error(error);
    }
  };

  return (
    <>
      <div className='w-full p-4'>{isLoading && <Loader />}</div>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
        <a
          href='/'
          className='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'
        >
          <img
            className='h-20 mr-2'
            src={emdaLogo}
            width='100'
            alt='logo'
          ></img>
          Reset Password
        </a>
        <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <div className='flex flex-col items-center justify-center'>
              <h1 className='text-xl font-bold text-gray-900 md:text-2xl dark:text-white'>
                Create a New Password
              </h1>
            </div>
            <form className='flex max-w-md flex-col gap-4' onSubmit={onSubmit}>
              <div>
                <div className='mb-2 block'>
                  <Label htmlFor='email' value='Your email' />
                </div>
                <TextInput
                  id='email'
                  name='email'
                  type='email'
                  placeholder='your@email.com'
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div>
                <div className='mb-2 block'>
                  <Label htmlFor='pass' value='New password' />
                </div>
                <TextInput
                  id='pass'
                  name='password'
                  type='password'
                  onChange={handleChange}
                  value={formData.password}
                  required
                />
              </div>
              <div>
                <div className='mb-2 block'>
                  <Label htmlFor='passconfirm' value='Confirm New password' />
                </div>
                <TextInput
                  id='passconfirm'
                  name='passwordConfirm'
                  type='password'
                  onChange={handleChange}
                  value={formData.passwordConfirm}
                  required
                />
              </div>
              <button
                disabled={buttonDisabled}
                type='submit'
                className='focus:outline-none text-white bg-mainButton hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
