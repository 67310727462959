import { Table, TableCell } from 'flowbite-react';
import { SearchResult } from '../../Models/SearchResult';
import {
  MdLocationPin,
  MdLocalPhone,
  MdEmail,
  MdInfo,
  MdTag,
} from 'react-icons/md';
import { FaEarthAmericas } from 'react-icons/fa6';
import blankImage from '../../Assets/blank.png';
import { Link } from 'react-router-dom';
export default function SearchTable({
  data,
  setShow,
}: {
  data: SearchResult[];
  setShow: (value: React.SetStateAction<boolean>) => void;
}) {
  const TABLE_ROWS = data;
  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <Table>
          <Table.Head>
            <Table.HeadCell className='w-1/4'>Logo</Table.HeadCell>
            <Table.HeadCell className='w-3/4'>Info</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {TABLE_ROWS.map((row) => (
              // <Table.Row className='bg-white dark:border-gray-700 dark:bg-gray-800'>
              <Table.Row key={row.name ?? ''}>
                <Table.Cell className='px-1'>
                  <Link to={`/member/${row.id}`} onClick={() => setShow(false)}>
                    <div className='h-40 overflow-hidden rounded-md'>
                      <img src={row.logo ?? blankImage} alt='...' />{' '}
                    </div>
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/member/${row.id}`} onClick={() => setShow(false)}>
                    <div className='flex p-1 font-bold text-2xl text-mainButton'>
                      {row.name}
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <MdInfo />
                      </div>
                      <div className='font-bold'>Industry:</div>
                      <div className='pl-1'>{row.industryName}</div>
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <MdLocationPin />
                      </div>
                      <div className='font-bold'>Address:</div>
                      <div className='pl-1'>
                        {row.city}, {row.state}
                      </div>
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <MdLocalPhone />
                      </div>
                      <div className='font-bold'>Phone:</div>
                      <div className='pl-1 text-mainButton'>{row.phone}</div>
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <FaEarthAmericas />
                      </div>
                      <div className='font-bold'>Website:</div>
                      <div className='pl-1 text-mainButton'>{row.website}</div>
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <MdEmail />
                      </div>
                      <div className='font-bold'>Email:</div>
                      <div className='pl-1 text-mainButton'>{row.email}</div>
                    </div>
                    <div className='flex p-1 text-lg'>
                      <div className='px-1 content-center'>
                        <MdTag />
                      </div>
                      <div className='font-bold'>Tags:</div>
                      <div className='pl-1 text-mainButton'>{row.tags}</div>
                    </div>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}
