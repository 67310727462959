import React from 'react';
import { MoonLoader } from 'react-spinners';

export default function Loader() {
  return (
    <div className='fixed flex justify-center items-center w-full h-full top-0 left-0 z-50 bg-gray-400/50'>
      <MoonLoader />
    </div>
  );
}
