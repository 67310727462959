import { createReducer } from '@reduxjs/toolkit';
import { clearState } from '../Actions/index';
import {
  fetchFileTypes,
  fetchIndustries,
  fetchPageTypes,
  fetchStates,
  fetchUserLevels,
  fetchSubscriptions,
  fetchTags,
  fetchMembers,
} from '../Actions/CommonFields';
import { CommonFieldsReference } from '../Models/CommonFieldsReference';

const initialState: CommonFieldsReference = {
  states: [],
  userLevels: [],
  industries: [],
  fileTypes: [],
  pageTypes: [],
  subscriptions: [],
  tags: [],
  members: [],
};

const CommonFieldsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchStates.fulfilled, (state, action) => {
      return { ...state, states: action.payload };
    })
    .addCase(fetchUserLevels.fulfilled, (state, action) => {
      return { ...state, userLevels: action.payload };
    })
    .addCase(fetchPageTypes.fulfilled, (state, action) => {
      return { ...state, pageTypes: action.payload };
    })
    .addCase(fetchIndustries.fulfilled, (state, action) => {
      return { ...state, industries: action.payload };
    })
    .addCase(fetchFileTypes.fulfilled, (state, action) => {
      return { ...state, fileTypes: action.payload };
    })
    .addCase(fetchSubscriptions.fulfilled, (state, action) => {
      return { ...state, subscriptions: action.payload };
    })
    .addCase(fetchTags.fulfilled, (state, action) => {
      return { ...state, tags: action.payload };
    })
    .addCase(fetchMembers.fulfilled, (state, action) => {
      return { ...state, members: action.payload };
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default CommonFieldsReducer;
