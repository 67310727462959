import { useEffect, useState } from 'react';
import Loader from '../Common/Loader';
import { File } from '../../Models/File';
import FilesApi from '../../Api/FilesApi';
import FileListTable from './FileListTable';
import CommonApi from '../../Api/CommonApi';
import { toast } from 'react-toastify';

export default function FileList({
  reload,
  setReload,
}: {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [filesData, setFilesData] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    if (reload) {
      getFiles();
      setReload(false);
    }
  }, [reload]);

  const getFiles = () => {
    try {
      setIsLoading(true);
      return FilesApi.getResourceFiles()
        .then((res) => {
          setFilesData(res.data);
          setIsLoading(false);
          setReload(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const deleteFile = (id: number) => {
    CommonApi.deleteFile(id)
      .then((res) => {
        if (res.data.success) {
          getFiles();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete file');
      });
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <FileListTable data={filesData} deleteFile={deleteFile} />
        </div>
      </div>
    </>
  );
}
