import { createReducer } from '@reduxjs/toolkit';

import {
  clearState,
  setUserFromToken,
  fetchCurrentUser,
} from '../Actions/index';
import { User } from '../Models/User';

type UserReducerType = {
  email: string;
  exp: number;
  authenticated: boolean;
  userModel: User | null;
  fetchingUser: boolean;
};

const initialState: UserReducerType = {
  email: '',
  exp: 0,
  authenticated: false,
  userModel: null,
  fetchingUser: false,
};

const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserFromToken, (state, action) => {
      return {
        ...state,
        email: action.payload.email,
        exp: action.payload.exp,
        authenticated: true,
      };
    })
    .addCase(fetchCurrentUser.fulfilled, (state, action) => {
      return { ...state, userModel: action.payload, fetchingUser: false };
    })
    .addCase(fetchCurrentUser.pending, (state, action) => {
      return { ...state, fetchingUser: true };
    })
    .addCase(fetchCurrentUser.rejected, (state, action) => {
      return { ...state, fetchingUser: false };
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default UserReducer;
