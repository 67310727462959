import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { State } from '../Models/State';
import { UserLevel } from '../Models/UserLevel';
import { Industry } from '../Models/Industry';
import { FileType } from '../Models/FileType';
import { ResponseModel } from '../Models/ResponseModel';
import { PageType } from '../Models/PageType';
import { Subscription } from '../Models/Subscription';
import { Tag } from '../Models/Tag';
import { Member } from '../Models/Member';

class CommonApi {
  static getStates() {
    return axios.get<State[]>(`${API_URL}/api/common/states`, getAuthHeader());
  }
  static getMembers() {
    return axios.get<Member[]>(
      `${API_URL}/api/common/members`,
      getAuthHeader()
    );
  }
  static getUserLevels() {
    return axios.get<UserLevel[]>(
      `${API_URL}/api/common/userlevels`,
      getAuthHeader()
    );
  }
  static getIndustries() {
    return axios.get<Industry[]>(
      `${API_URL}/api/common/industries`,
      getAuthHeader()
    );
  }
  static getFileTypes() {
    return axios.get<FileType[]>(
      `${API_URL}/api/common/filetypes`,
      getAuthHeader()
    );
  }
  static getSubscriptions() {
    return axios.get<Subscription[]>(
      `${API_URL}/api/common/subscriptions`,
      getAuthHeader()
    );
  }
  static addFile(request: FormData) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/common/addFile`,
      request,
      getAuthHeader()
    );
  }
  static deleteFile(id: number) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/common/deleteFile/${id}`,
      {},
      getAuthHeader()
    );
  }
  static getPageTypes() {
    return axios.get<PageType[]>(
      `${API_URL}/api/common/pagetypes`,
      getAuthHeader()
    );
  }
  static getTags() {
    return axios.get<Tag[]>(`${API_URL}/api/common/tags`, getAuthHeader());
  }
  static deleteSitePage(id: number) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/common/deleteSitePage/${id}`,
      {},
      getAuthHeader()
    );
  }
}

export default CommonApi;
