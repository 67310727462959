import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { displayDateOnly } from '../../Utils';
import { DisplayAd } from '../../Models/DisplayAd';
import blankImage from '../../Assets/blank.png';
import { Button, Tooltip } from 'flowbite-react';
import { BiSolidReport } from 'react-icons/bi';
import MemberDisplayAdsDataModal from './MemberDisplayAdsDataModal';
import { useState } from 'react';

export default function MemberDisplayAdsListTable({
  data,
}: {
  data: DisplayAd[];
}) {
  const [showAdReport, setShowAdReport] = useState<boolean>(false);
  const [selectedDisplayAd, setSelectedDisplayAd] = useState<DisplayAd | null>(
    null
  );

  const handleViewAdReportClick = (ad: DisplayAd) => {
    setSelectedDisplayAd(ad);
    setShowAdReport(true);
  };
  const columnHelper = createColumnHelper<DisplayAd>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Tooltip content='View Report'>
            <Button
              type='button'
              className='buttonMain p-2'
              size={20}
              onClick={() => handleViewAdReportClick(info.row.original)}
            >
              <BiSolidReport />
            </Button>
          </Tooltip>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.imageUrl, {
      id: 'imageUrl',
      header: 'Image',
      cell: (info) => (
        <div className='flex flex-col w-full mx-auto object-contain h-24'>
          <img
            src={info.getValue() ?? blankImage}
            alt={'image'}
            className='object-contain h-24'
          />
        </div>
      ),
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: 'startDate',
      header: 'Start Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),

      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: 'endDate',
      header: 'End Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'clicks',
      header: 'Clicks',
      cell: (info) =>
        info.row.original.displayAdEvents
          ? info.row.original.displayAdEvents.length
          : 0,
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
      <MemberDisplayAdsDataModal
        show={showAdReport}
        setShow={setShowAdReport}
        selectedDisplayAd={selectedDisplayAd}
        setSelectedDisplayAd={setSelectedDisplayAd}
      />
    </>
  );
}
