import { useEffect, useState } from 'react';
import Loader from '../Common/Loader';
import { Invoice } from '../../Models/Invoice';
import InvoiceApi from '../../Api/InvoiceApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InvoiceListTable from './InvoiceListTable';

export default function InvoiceList() {
  const { id } = useParams();
  //   const { invoicesModel } = useAppSelector((state) => state.invoice);
  const [invoicesData, setInvoicesData] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getInvoices();
  }, [id]);

  const getInvoices = () => {
    if (id) {
      try {
        setIsLoading(true);
        return InvoiceApi.getInvoices(id)
          .then((res) => {
            setInvoicesData(res.data);
            setIsLoading(false);
          })
          .catch((err) => {});
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <InvoiceListTable data={invoicesData} />
        </div>
      </div>
    </>
  );
}
