import { useEffect, useState } from "react";
import {
  HiOutlineIdentification,
  HiHome,
  HiUsers,
  HiFolderOpen,
  HiOutlineCollection,
  HiCalendar,
  HiOutlinePresentationChartBar,
  HiOutlineSun,
} from "react-icons/hi"; //https://react-icons.github.io/react-icons/icons/hi/
import { Sidebar, Tooltip } from "flowbite-react";
import { useAppSelector } from "../../Reducers/store";
import { UserLevelNames } from "../../Models/UserLevel";
import { MdTag } from "react-icons/md";
import { SitePage } from "../../Models/SitePage";
import SitePageApi from "../../Api/SitePageApi";
import { Link } from "react-router-dom";
import MemberApi from "../../Api/MemberApi";
import Loader from "../Common/Loader";

export default function SideNav() {
  const { userModel } = useAppSelector((state) => state.user);
  const [pagesData, setPagesData] = useState<SitePage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSitePages();
  }, []);

  const getSitePages = () => {
    try {
      return SitePageApi.getSitePageListForMenu()
        .then((res) => {
          setPagesData(res.data);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
    }
  };

  const downloadPdfNow = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.getMemberDirectory();

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Member Directory.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setIsLoading(false);
    }
  };

  function checkAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    }
    return false;
  }

  return (
    <>
      {isLoading && <Loader />}
      <Sidebar className="bg-slate-100 w-[70px] lg:w-[250px] h-[calc(100vh-78px)] transition-width transition-slowest">
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <div className="flex content-center">
              <Sidebar.Item href="/" icon={HiHome}>
                <div className="hidden lg:block">Dashboard</div>
              </Sidebar.Item>
            </div>
            <div className="flex content-center">
              <Sidebar.Item href="/members" icon={HiOutlineCollection}>
                <div className="hidden lg:block">Member List</div>
              </Sidebar.Item>
            </div>
            {userModel &&
              userModel.userLevelId != UserLevelNames.SuperAdmin && (
                <div className="flex content-center">
                  <Sidebar.Item
                    href={`/member/${userModel?.memberId}`}
                    icon={HiOutlineIdentification}
                  >
                    <div className="hidden lg:block">Member Profile</div>
                  </Sidebar.Item>
                </div>
              )}
            <div className="flex content-center">
              <Sidebar.Item href="/members/fallconvention" icon={HiCalendar}>
                <div className="hidden lg:block">Fall Convention</div>
              </Sidebar.Item>
            </div>
            <div className="flex content-center">
              <Sidebar.Item
                href="/members/industryshowcase"
                icon={HiOutlinePresentationChartBar}
              >
                <div className="hidden lg:block">Industry Showcase</div>
              </Sidebar.Item>
            </div>
            <div className="flex content-center">
              <Sidebar.Item href="/members/springmeeting" icon={HiOutlineSun}>
                <div className="hidden lg:block">Spring Meeting</div>
              </Sidebar.Item>
            </div>
            <div className="flex content-center">
              <Sidebar.Collapse icon={HiFolderOpen} label="Member Resources">
                {checkAccess() && (
                  <Link to={`/members/resources`}>
                    <div className="w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100">
                      Manage
                    </div>
                  </Link>
                )}
                <Link to="members/directory">
                  <div className="w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100">
                    Member Directory
                  </div>
                </Link>
                <Link to="member/files">
                  <div className="w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100">
                    Download Files
                  </div>
                </Link>
                {pagesData &&
                  pagesData.length > 0 &&
                  pagesData.map((page) => (
                    <Link key={page.id} to={`/page/${page.id}`}>
                      <div className="w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100">
                        {page.title}
                      </div>
                    </Link>
                  ))}
              </Sidebar.Collapse>
            </div>
            <div className="flex content-center">
              <Sidebar.Item href="/members/tags" icon={MdTag}>
                <div className="hidden lg:block">Search By Tags</div>
              </Sidebar.Item>
            </div>
            <div className="flex content-center">
              <Sidebar.Item href="/users" icon={HiUsers}>
                <div className="hidden lg:block">User List</div>
              </Sidebar.Item>
            </div>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
}
