import { User } from '../../Models/User';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Link } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
export default function UserListTable({ data }: { data: User[] }) {
  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Link to={`/user/${info.getValue()}`} className='buttonMain p-2'>
            <HiUser></HiUser>
          </Link>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      header: 'First Name',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      header: 'Last Name',
      sortingFn: 'alphanumeric',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: 'phoneNumber',
      header: 'Phone',
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.memberId, {
      id: 'memberId',
      header: 'Member',
      cell: (info) => (
        <i>{info.getValue() ? info.row.original.member?.name : 'EMDA'}</i>
      ),
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'lastName',
          desc: false,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
