import { useEffect, useState } from 'react';
import Loader from '../Common/Loader';
import { File } from '../../Models/File';
import FilesApi from '../../Api/FilesApi';
import DownloadFilesTable from './DownloadFilesTable';
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/member/files", title: "Member Files" });

export default function DownloadFiles() {
  const [filesData, setFilesData] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = () => {
    try {
      setIsLoading(true);
      return FilesApi.getResourceFiles()
        .then((res) => {
          setFilesData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full h-[74px]'>
          <div className='w-full h-full flex p-3'>
            <h1 className='text-gray-900 font-bold text-4xl my-auto'>
              Download Files
            </h1>
          </div>
        </div>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <DownloadFilesTable data={filesData} />
        </div>
      </div>
    </>
  );
}
