import { useEffect, useState } from "react";
import Loader from "../Common/Loader";
import { SitePage } from "../../Models/SitePage";
import SitePageApi from "../../Api/SitePageApi";
import ReactGA from "react-ga4";

export default function CustomSitePage() {
  const [pageData, setPageData] = useState<SitePage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSitePage();
  }, []);
  const getSitePage = () => {
    try {
      setIsLoading(true);
      return SitePageApi.getSitePage("14")
        .then((res) => {
          setPageData(res.data);
          ReactGA.send({
            hitType: "pageview",
            page: "/page",
            title: "Site Page: " + pageData?.title,
          });
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <title>{pageData?.title}</title>
      <div className="w-full">
        <div className="w-full p-4">
          <div className="mx-1 flex items-end">
            <div className="flex flex-1">
              <h5 className="text-gray-900 font-bold text-4xl">
                {pageData?.header}
              </h5>
            </div>
          </div>
        </div>
        <div className="p-5">
          {isLoading && <Loader />}
          <div
            dangerouslySetInnerHTML={{
              __html: pageData?.description ?? "",
            }}
          />
        </div>
      </div>
    </>
  );
}
