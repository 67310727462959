import { Button, Drawer } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import blankImage from '../../Assets/blank.png';
import { Link } from 'react-router-dom';
import SearchApi from '../../Api/SearchApi';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { SearchRequest } from '../../Models/SearchRequest';
import { SearchResult } from '../../Models/SearchResult';
import { SelectOption } from '../../Models/SelectOption';
import { fetchTags } from '../../Actions/CommonFields';
import Loader from '../Common/Loader';
import { MdTag } from 'react-icons/md';

export default function MemberTags() {
  const dispatch = useAppDispatch();
  const { tags } = useAppSelector((state) => state.commonFields);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [formData, setFormData] = useState<SearchRequest>({
    searchText: '',
    category: '',
    selectedTagIds: [],
  });

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  const getReferences = async () => {
    if (!tags.length) {
      await Promise.all([dispatch(fetchTags())]);
    }
  };

  useEffect(() => {
    getReferences();
    getSearchResults();
  }, []);

  const tagsList = useMemo(() => {
    const list = tags.map((t) => ({
      label: t.name,
      value: t.id!,
    })) as SelectOption[];
    return [...list];
  }, [tags]);

  const getSearchResults = async () => {
    setIsLoading(true);
    if (selectedOptions) {
      formData.selectedTagIds = selectedOptions.map(function (item) {
        return item.value!;
      });
    } else {
      formData.selectedTagIds = [];
    }
    SearchApi.search(formData)
      .then((res) => {
        setSearchData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      getSearchResults();
      return;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  function updateSelectedTags(clickedTag: SelectOption) {
    if (selectedOptions?.includes(clickedTag)) {
      setSelectedOptions(
        selectedOptions.filter((x) => x.value !== clickedTag.value)
      );
    } else {
      setSelectedOptions(selectedOptions.concat(clickedTag));
    }
  }

  return (
    <div className='w-full mt-4'>
      <div className='w-full h-[74px]'>
        <div className='w-full h-full flex p-3'>
          <h1 className='text-gray-900 font-bold text-4xl my-auto'>
            Search Tags
          </h1>
        </div>
      </div>
      <div className='container mx-auto p-2'>
        <div className='flex mb-2 border-b-2'>
          <Button className='buttonMain' onClick={() => setIsOpen(true)}>
            Show All Tags
          </Button>
          {selectedOptions.length > 0 && (
            <div className='my-auto'>
              Selected Tags:{' '}
              {selectedOptions
                ?.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''))
                .map((tag) => (
                  <button
                    type='button'
                    key={tag.value}
                    className={`border border-1 px-2 rounded-md ${
                      selectedOptions.includes(tag)
                        ? 'bg-gray-400 text-white'
                        : 'hover:bg-gray-400 hover:text-white'
                    } text-sm`}
                    disabled
                  >
                    {tag.label}
                  </button>
                ))}
            </div>
          )}
        </div>

        <div
          id='scrollableDiv'
          className='h-[calc(100vh-170px)] overflow-y-auto'
        >
          {isLoading && <Loader />}
          {searchData && (
            <div className='flex flex-wrap gap-10 justify-center'>
              {searchData.map((data) => (
                <div
                  key={data.id}
                  className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6'
                >
                  <Link to={`/member/${data.id}`}>
                    <img
                      className='h-56 sm:h-64 xl:h-80 2xl:h-80 object-contain m-auto'
                      alt={data.name! + 'Logo'}
                      src={data.logo ? data.logo : blankImage}
                    />
                    <div>
                      <h5 className='text-xl font-bold tracking-tight text-gray-900 text-center'>
                        {data.name!}
                      </h5>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Drawer open={isOpen} onClose={handleClose}>
        <Drawer.Header title='Tags' titleIcon={MdTag} />
        <Drawer.Items>
          <form onSubmit={onSubmit}>
            <div className=''>
              {tagsList
                ?.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''))
                .map((tag) => (
                  <button
                    type='submit'
                    key={tag.value}
                    className={`border border-1 px-2 rounded-md ${
                      selectedOptions.includes(tag)
                        ? 'bg-gray-400 text-white'
                        : 'hover:bg-gray-400 hover:text-white'
                    } text-sm`}
                    onClick={() => updateSelectedTags(tag)}
                  >
                    {tag.label}
                  </button>
                ))}
            </div>
          </form>
        </Drawer.Items>
      </Drawer>
    </div>
  );
}
