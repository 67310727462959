import { useAppSelector } from '../../Reducers/store';
import { useEffect, useState } from 'react';
import { UserLevelNames } from '../../Models/UserLevel';
import { Button, Tooltip } from 'flowbite-react';
import { HiOutlinePlus } from 'react-icons/hi';
import SitePagesList from './SitePagesList';
import Loader from '../Common/Loader';
import { SitePage } from '../../Models/SitePage';
import EditSitePage from './EditSitePage';
import SitePageApi from '../../Api/SitePageApi';
import CommonApi from '../../Api/CommonApi';
import { toast } from 'react-toastify';

export default function MemberSitePages() {
  const { userModel } = useAppSelector((state) => state.user);
  const [showAddEditSitePage, setShowAddEditSitePage] =
    useState<boolean>(false);
  const [selectedSitePage, setSelectedSitePage] = useState<number | null>(null);

  const [pagesData, setPagesData] = useState<SitePage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    checkAccess();
  }, []);

  useEffect(() => {
    if (!showAddEditSitePage) getSitePages();
  }, [showAddEditSitePage]);

  const getSitePages = () => {
    try {
      setIsLoading(true);
      return SitePageApi.getSitePageList()
        .then((res) => {
          setPagesData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  function checkAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    } else {
      return false;
    }
  }

  const handleAddSitePageClick = () => {
    setShowAddEditSitePage(true);
  };

  const deleteFile = (id: number) => {
    CommonApi.deleteSitePage(id)
      .then((res) => {
        if (res.data.success) {
          getSitePages();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete file');
      });
  };

  return (
    <>
      {checkAccess() && (
        <div className='float-right pr-6'>
          {userModel && userModel.userLevelId === UserLevelNames.SuperAdmin && (
            <Tooltip content='Add File'>
              <Button onClick={handleAddSitePageClick} className='buttonMain'>
                <HiOutlinePlus />
              </Button>{' '}
            </Tooltip>
          )}
        </div>
      )}
      <div className='w-full'>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <SitePagesList
            data={pagesData}
            setSelectedSitePage={setSelectedSitePage}
            setShowSitePage={setShowAddEditSitePage}
            accessCheck={checkAccess()}
            deleteFile={deleteFile}
          />
        </div>
      </div>
      <EditSitePage
        selectedSitePage={selectedSitePage}
        show={showAddEditSitePage}
        setShow={setShowAddEditSitePage}
        setSelectedSitePage={setSelectedSitePage}
      />
    </>
  );
}
