import { useEffect, useState } from "react";
import MemberApi from "../../Api/MemberApi";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "pdfjs-dist/build/pdf.worker.entry";
import ReactGA from "react-ga4";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { Button, Tooltip } from "flowbite-react";
import Loader from "../Common/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
ReactGA.send({
  hitType: "pageview",
  page: "/members/directory",
  title: "Directory",
});

export default function MemberDirectory() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfURL, setPDFURL] = useState<string>("");

  function onDocumentLoadSuccess(document: PDFDocumentProxy) {
    if (document && document.numPages) {
      setNumPages(document.numPages);
      setPageNumber(1);
    }
  }
  useEffect(() => {
    setIsLoading(true);
    downloadPdf();
  }, []);

  const downloadPdf = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.getMemberDirectory();

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);
      setPDFURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setIsLoading(false);
    }
  };
  const downloadPdfNow = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.getMemberDirectory();

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Member Directory.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="w-full p-3">
        <div className="w-full h-[74px]">
          <div className="w-full h-full flex p-3">
            <h1 className="text-gray-900 font-bold text-4xl my-auto">
              {/* {memberData?.name} */}
              Member Directory
            </h1>
          </div>
        </div>
        <div className="right-2 p-3">
          <Tooltip content="Download Report">
            <Button className="buttonMain" onClick={downloadPdfNow}>
              Download Report
            </Button>
          </Tooltip>
        </div>
        {!isLoading && (
          <div>
            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} className="" />
            </Document>
            <div className="">
              <button
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                type="button"
                aria-label="Previous page"
                className="px-2 enabled:hover:bg-gray-200 rounded font-bold"
              >
                ‹
              </button>
              <span>
                {pageNumber} of {numPages}
              </span>
              <button
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                type="button"
                aria-label="Next page"
                className="px-2 enabled:hover:bg-gray-200 rounded font-bold"
              >
                ›
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
