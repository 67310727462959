export interface UserLevel {
  id: number;
  name: string;
}

export enum UserLevelNames {
  SuperAdmin = 1,
  MemberAdmin = 2,
  MemberUser = 3,
}
