import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import UserListTable from './UserListTable';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { UserListRequest } from '../../Models/UserListRequest';
import MemberApi from '../../Api/MemberApi';
import { User } from '../../Models/User';
import { useAppSelector } from '../../Reducers/store';
import AddUserModal from './AddUserModal';
import { UserLevelNames } from '../../Models/UserLevel';
import { useParams } from 'react-router-dom';

export default function UserList() {
  const { id } = useParams();
  const { userModel } = useAppSelector((state) => state.user);
  const [formData, setFormData] = useState<UserListRequest>({
    firstName: '',
    lastName: '',
    emailAddress: '',
  });
  const [userListData, setUserListData] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [showAddUser, setShowAddUser] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
  }, [userModel]);

  const getUsers = () => {
    if (userModel) {
      try {
        setIsLoading(true);
        return MemberApi.getMemberUserList(userModel?.memberId ?? 0, formData)
          .then((res) => {
            setUserListData(res.data);

            setIsLoading(false);
          })
          .catch((err) => {
            toast.error('Error');
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
        toast.error('Error');
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      return MemberApi.getMemberUserList(userModel?.memberId ?? 0, formData)
        .then((res) => {
          setUserListData(res.data);
          toast.success('success');
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // const handleAddUserClick = () => {
  //   setShowAddUser(true);
  // };

  // function checkAccess() {
  //   if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  return (
    <>
      <div className='w-full'>
        <div className='w-full h-[74px] p-4'>
          <div className='mx-1 flex items-end'>
            <div className='flex flex-1'>
              <h1 className='text-gray-900 font-bold text-4xl'>
                <div>Full User List</div>
              </h1>
            </div>
            {/* {checkAccess() && (
              <div className='absolute right-2'>
                <Button onClick={handleAddUserClick} className='buttonMain'>
                  Add Super User
                </Button>
              </div>
            )} */}
          </div>
        </div>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          {/* <Loader></Loader> */}
          <UserListTable data={userListData} />
        </div>
      </div>
      {/* <AddUserModal
        show={showAddUser}
        setShow={setShowAddUser}
        memberId={userModel?.memberId ?? null}
      ></AddUserModal> */}
    </>
  );
}
