import { useAppSelector } from '../../Reducers/store';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Member } from '../../Models/Member';
import UserApi from '../../Api/UserApi';
import { Link } from 'react-router-dom';
import { HiUser, HiXCircle } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import PaginatedTable from '../Common/PaginatedTable';
import { toast } from 'react-toastify';
import UserFavoriteApi from '../../Api/UserFavoriteApi';
import { Favorites } from '../../Models/Favorites';

export default function UserFavorites() {
  const { userModel } = useAppSelector((state) => state.user);
  const [data, setData] = useState<Favorites[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getFavorites();
  }, []);

  const getFavorites = () => {
    if (userModel) {
      try {
        setData([]);
        setIsLoading(true);
        return UserApi.getUserFavorites()
          .then((res) => {
            setData(res.data);

            setIsLoading(false);
          })
          .catch((err) => {
            toast.error('Error');
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
        toast.error('Error');
        setIsLoading(false);
      }
    }
  };

  const removeFavorite = (id: string) => {
    UserFavoriteApi.removeUserFavorite(id)
      .then((res) => {
        getFavorites();
        toast.success('Removed from favorites');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to remove favorite');
      });
  };

  const columnHelper = createColumnHelper<Favorites>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Link
            to={
              info.row.original.userId
                ? `/user/` + info.row.original.userId
                : `/member/` + info.row.original.memberId
            }
            className='buttonMain p-2'
          >
            <HiUser size={20} />
          </Link>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'delete',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <button
            className='buttonCancel p-2'
            onClick={() => removeFavorite(info.getValue().toString())}
          >
            <HiXCircle size={20} />
          </button>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
  ];

  // const columns = useMemo(() => columnData, [data]);

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div className='border-2 border-rose-600 rounded-md'>
      <div className='flex justify-center'>
        <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
          <span className='tracking-wide uppercase text-3xl'>Favorites</span>
        </div>
      </div>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </div>
  );
}
