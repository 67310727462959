import { Button, Carousel, Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';
import Loader from '../Common/Loader';
import blankImage from '../../Assets/blank.png';
import { Link, useParams } from 'react-router-dom';
import UserApi from '../../Api/UserApi';
import { User } from '../../Models/User';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { HiPencilAlt, HiOutlineStar, HiStar } from 'react-icons/hi';
import UserFavoriteApi from '../../Api/UserFavoriteApi';
import { MdEmail, MdLocalPhone, MdLocationPin } from 'react-icons/md';
import DOMPurify from 'isomorphic-dompurify';

export default function UserProfile() {
  const { userModel } = useAppSelector((state) => state.user);
  const { id } = useParams();
  const [userData, setUserData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [favorite, setFavorite] = useState<boolean | null | undefined>(false);

  const getUser = () => {
    if (id) {
      setIsLoading(true);
      UserApi.getUser(id)
        .then((res) => {
          setUserData(res.data);
          if (
            userModel?.userFavorites?.some(
              (x) => x.userFavoriteId === res.data?.id
            )
          ) {
            setFavorite(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  const iconstyle = { color: '#5b9d30' };

  function checkAccess() {
    if (userData && userModel && id) {
      if (userModel.userLevelId === UserLevelNames.SuperAdmin) {
        return true;
      } else if (
        userModel.memberId === userData.memberId &&
        userModel.id === Number(id)
      ) {
        return true;
      } else if (
        userModel.memberId === userData.memberId &&
        userModel.userLevelId === UserLevelNames.MemberAdmin
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  const addFavorite = () => {
    if (userData) {
      if (favorite) {
        UserFavoriteApi.removeUserFavoriteUser(userData?.id?.toString());
      } else {
        UserFavoriteApi.addUserFavoriteUser(userData?.id?.toString());
      }
      setFavorite(!favorite);
    }
  };

  return (
    <div className='w-full'>
      <div className='w-full p-4'>
        <div className='mx-1 flex items-end'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>
              User Information
            </h1>
          </div>
          {checkAccess() && (
            <div className='right-2'>
              <Link to={`/edit/user/${userData?.id}`}>
                <Tooltip content='Edit Details'>
                  <Button className='buttonMain'>
                    <HiPencilAlt size={20} />
                  </Button>
                </Tooltip>
              </Link>
            </div>
          )}
          <div className='right-2'>
            <Tooltip content='Toggle Favorite Status'>
              <Button className='buttonMain' onClick={() => addFavorite()}>
                {favorite ? <HiStar size={20} /> : <HiOutlineStar size={20} />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto p-2'>
          <div className='md:flex no-wrap md:-mx-2 '>
            {/* <!-- Left Side --> */}
            <div className='w-full md:w-2/12 md:mx-2'>
              {/* <!-- Profile Card --> */}
              <div className='h-72 overflow-hidden rounded-md'>
                <img src={userData?.image?.url ?? blankImage} alt='...' />
              </div>
              {/* <!-- End of profile card --> */}
            </div>
            {/* <!-- Right Side --> */}
            <div className='w-full md:w-10/12 mx-2'>
              {' '}
              {/* <!-- Profile tab --> */}
              {/* <!-- About Section --> */}
              <div className='bg-white p-3'>
                <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
                  <span className='tracking-wide uppercase text-3xl'>
                    {userData?.firstName} {userData?.lastName}
                  </span>
                </div>
                <hr className='bg-gray-300 h-[5px]'></hr>
                <div className='text-gray-500'>
                  <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                    <div className='flex'>
                      <div className='px-1 content-center'>
                        <MdLocalPhone style={iconstyle} />
                      </div>
                      <div className='font-bold'>Phone:</div>
                    </div>
                    <div className='pl-1 text-mainButton'>
                      {userData?.phoneNumber}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[2px]'></hr>
                  <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                    <div className='flex'>
                      <div className='px-1 content-center'>
                        <MdEmail style={iconstyle} />
                      </div>
                      <div className='font-bold'>Email:</div>
                    </div>
                    <div className='pl-1 text-mainButton'>
                      {userData?.emailAddress}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[2px]'></hr>
                  <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                    <div className='flex'>
                      <div className='px-1 content-center'>
                        <MdLocationPin style={iconstyle} />
                      </div>
                      <div className='font-bold'>Address:</div>
                    </div>
                    <div className='pl-1 text-mainButton'>
                      {userData?.address} {userData?.city}, {userData?.state}{' '}
                      {userData?.zip}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[2px]'></hr>
                  <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                    <div className='flex'>
                      {/* <div className='px-1 content-center'>
                        <FaSquareFacebook style={iconstyle} />
                      </div> */}
                      <div className='font-bold'>User Level:</div>
                    </div>
                    <div className='pl-1 text-mainButton'>
                      {userData?.userLevel?.name}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[2px]'></hr>
                  <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                    <div className='flex'>
                      {/* <div className='px-1 content-center'>
                        <FaTwitter style={iconstyle} />
                      </div> */}
                      <div className='font-bold'>Bio:</div>
                    </div>
                    <div className='pl-1 text-mainButton whitespace-pre-line'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(userData?.bio ?? ''),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of about section --> */}
              {/* <!-- End of profile tab --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
