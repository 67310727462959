import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import MemberApi from '../../Api/MemberApi';
import { useAppSelector } from '../../Reducers/store';
import MemberListTable from './MemberListTable';
import { Member } from '../../Models/Member';
import ReactGA from "react-ga4";
ReactGA.send({ hitType: "pageview", page: "/members", title: "Member List" });

export default function MemberList() {
  const { userModel } = useAppSelector((state) => state.user);
  const [memberListData, setMemberListData] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getMembers();
  }, [userModel]);

  const getMembers = () => {
    try {
      setIsLoading(true);
      return MemberApi.getMembers()
        .then((res) => {
          setMemberListData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('Error');
          console.error(err);
          setIsLoading(false);
        });
    } catch (error) {
      toast.error('Error');
      console.error(error);
      setIsLoading(false);
    }
  };
  return (
    <div className='w-full'>
      <div className='w-full h-[74px]'>
        <div className='w-full h-full flex p-3'>
          <h1 className='text-gray-900 font-bold text-4xl my-auto'>
            Member List
          </h1>
        </div>
      </div>
      <div className='w-full p-4'>
        {isLoading && <Loader />}
        <MemberListTable data={memberListData} />
      </div>
    </div>
  );
}
