/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import AuthApi from '../Api/AuthApi';
import { DecodedToken } from '../Models/DecodedToken';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { isAfter, toDate } from 'date-fns';

const CLEAR_STATE = 'CLEAR_STATE';
const CLEAR_SELECTED_CLAIM = 'CLEAR_SELECTED_CLAIM';

export const clearState = createAction(CLEAR_STATE);
export const clearSelectedClaim = createAction(CLEAR_SELECTED_CLAIM);

export const setUserFromToken = createAction<{ email: string; exp: number }>(
  'SET_USER_FROM_TOKEN'
);

export const fetchCurrentUser = createAsyncThunk(
  'FETCH_CURRENT_USER',
  async (_, thunkApi) => {
    try {
      const response = await AuthApi.getCurrentUser();
      return response.data;
    } catch (error) {
      if ((error as any).response.status === 401) {
        localStorage.removeItem('EMDAToken');
        window.location.href = '/login';
      } else {
        const { errorMessage } = handleError(error);
        return thunkApi.rejectWithValue(errorMessage);
      }
      return thunkApi.rejectWithValue('UnAuthorized');
    }
  }
);

export function handleError(error: any) {
  let errorMessage = '';
  if (error.response) {
    const response = error.response;
    errorMessage = response.data.error_description;

    if (
      process.env.NODE_ENV !== 'production' &&
      process.env.NODE_ENV !== 'test'
    ) {
      console.log('DOES NOT SHOW IN PRODUCTION - Error response: ', response);
    }

    // NOT AUTHENTICATED ERROR
    if (response.status === 401) {
      const token = localStorage.getItem('EMDAToken');
      if (token) {
        const decoded: DecodedToken = jwtDecode(token);
        const now = new Date();
        if (!isAfter(toDate(decoded.exp * 1000), now)) {
          window.location.href = '/login';
        } else {
          errorMessage = 'UnAuthorized. You are not authorized for this action';
        }
      } else {
        window.location.href = '/login';
      }
      toast.error(errorMessage);
      setTimeout(toast.clearWaitingQueue, 1500);
    }

    if (response.status === 500) {
      errorMessage = `A server error has occurred: ${response.data.error}`;
      toast.error(errorMessage);
    }
  } else {
    if (
      process.env.NODE_ENV !== 'production' &&
      process.env.NODE_ENV !== 'test'
    ) {
      console.error('DOES NOT SHOW IN PRODUCTION', error);
    }
    errorMessage = 'An error has occurred';
  }

  return {
    errorMessage,
  };
}
