import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { SitePage } from '../Models/SitePage';
import { ResponseModel } from '../Models/ResponseModel';

class SitePageApi {
  static getSitePage(id: string) {
    return axios.get<SitePage>(
      `${API_URL}/api/sitepage/GetSitePage/${id}`,
      getAuthHeader()
    );
  }

  static addUpdateSitePage(request: SitePage) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/sitepage/CreateUpdatePage`,
      request,
      getAuthHeader()
    );
  }

  static getSitePageList() {
    return axios.get<SitePage[]>(
      `${API_URL}/api/sitepage/GetSitePages`,
      getAuthHeader()
    );
  }

  static getSitePageListForMenu() {
    return axios.get<SitePage[]>(
      `${API_URL}/api/sitepage/GetSitePagesMenu`,
      getAuthHeader()
    );
  }
}

export default SitePageApi;
