import axios from 'axios';
import { API_URL, getAuthHeader, getPDFAuthHeader } from '.';
import { Invoice } from '../Models/Invoice';

class InvoiceApi {
  static getInvoices(memberId: string) {
    return axios.get<Invoice[]>(
      `${API_URL}/api/qb/GetInvoices/${memberId}`,
      getAuthHeader()
    );
  }

  static getInvoiceDownload(invoiceId: string) {
    return axios.get(
      `${API_URL}/api/qb/GetInvoiceDL/${invoiceId}`,
      getPDFAuthHeader()
    );
  }
}
export default InvoiceApi;
