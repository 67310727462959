import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Button,
  Checkbox,
  FileInput,
  Modal,
  Select,
  TextInput,
  Textarea,
} from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import UserApi from '../../Api/UserApi';
import { fetchMembers } from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { DisplayAd } from '../../Models/DisplayAd';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import blankImage from '../../Assets/blank.png';
import {
  displayDateOnly,
  formatDateInputs,
  formatNumbers,
  onlyNumberInput,
} from '../../Utils';
import { DisplayAdEvent } from '../../Models/DisplayAdEvent';
import PaginatedTable from '../Common/PaginatedTable';
import { DisplayAdEventResult } from '../../Models/DisplayAdEventResult';

export default function MemberDisplayAdsDataModal({
  show,
  setShow,
  selectedDisplayAd,
  setSelectedDisplayAd,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDisplayAd: DisplayAd | null;
  setSelectedDisplayAd: React.Dispatch<React.SetStateAction<DisplayAd | null>>;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [data, setData] = useState<DisplayAdEventResult[]>([]);

  useEffect(() => {
    if (show === true && selectedDisplayAd) {
      getDisplayAdEventResults();
    }
  }, [show]);

  const getDisplayAdEventResults = () => {
    setIsLoading(true);
    DisplayAdsApi.getDisplayAdEventResults(selectedDisplayAd!.id)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    // setButtonDisabled(true);
    e.preventDefault();
    // try {
    //   setIsLoading(true);
    //   return DisplayAdsApi.downloadAdData(formData)
    //     .then((res) => {
    //       if (res.data.success) {
    //         if (formData.id === 0) {
    //           formData.id = Number(res.data.affectedEntityIdentifier);
    //         }
    //           toast.success('Ad Saved');
    //           setIsLoading(false);
    //           setButtonDisabled(false);
    //           // ReactGA.event({
    //           //   category: 'User',
    //           //   action: 'Saved Profile'
    //           // });
    //           onCloseModal();

    //       } else {
    //         toast.error(res.data.message);
    //         setButtonDisabled(false);
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error('Error: ' + err);
    //       setIsLoading(false);
    //       setButtonDisabled(false);
    //     });
    // } catch (error) {
    //   console.error(error);
    //   setIsLoading(false);
    //   setButtonDisabled(false);
    // }
  };
  function onCloseModal() {
    setSelectedDisplayAd(null);
    setData([]);
    setShow(false);
  }

  const columnHelper = createColumnHelper<DisplayAdEventResult>();

  const columns = [
    columnHelper.accessor((row) => row.memberName, {
      id: 'memberName',
      header: 'Member Name',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.memberPhone, {
      id: 'memberPhone',
      header: 'Member Phone',
      cell: (info) => info.getValue() ?? '',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.userName, {
      id: 'userName',
      header: 'User Name',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.userEmail, {
      id: 'userEmail',
      header: 'User Email',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.userPhone, {
      id: 'userPhone',
      header: 'User Phone',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.totalClicks, {
      id: 'totalClicks',
      header: 'Clicks',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.lastClickDate, {
      id: 'lastClickDate',
      header: 'Last Clicked',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Modal show={show} size='4xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        <div className='w-full h-[715px]'>
          <div className='w-full mb-5'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>Ad Report</h1>
              </div>
            </div>
          </div>

          <div>
            <div className='container mx-auto px-5'>
              <div className='w-full'>
                <div className='flex flex-col w-full mx-auto object-contain h-36'>
                  <img
                    src={selectedDisplayAd?.imageUrl ?? blankImage}
                    className='object-contain h-36'
                  />
                </div>
                <div className='w-full mx-auto overflow-x-auto'>
                  <PaginatedTable
                    table={table}
                    dataCount={data.length}
                    columnResizeMode='onChange'
                    showFilters={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
