import { Button, Modal, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import UserApi from '../../Api/UserApi';
import { User } from '../../Models/User';
import { ResetPasswordRequest } from '../../Models/ResetPasswordRequest';

export default function UserUpdatePassword({
  show,
  setShow,
  user,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<ResetPasswordRequest>({
    email: user.emailAddress ?? '',
    password: '',
    passwordConfirm: '',
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.password !== formData.passwordConfirm) {
      toast.error('Passwords do not match');
      return;
    }

    setButtonDisabled(true);
    try {
      return UserApi.updatePassword(user.id, formData)
        .then((res) => {
          if (res.data.success) {
            toast.success('Password Updated');
            setIsLoading(false);
            setButtonDisabled(false);
            onCloseModal();
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
            setButtonDisabled(false);
          }
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };
  function onCloseModal() {
    setShow(false);
  }
  return (
    <Modal show={show} size='xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='w-full'>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>
                  Update Password
                </h1>
              </div>
            </div>
          </div>

          <div>
            <div className='container mx-auto p-2'>
              <div className='md:flex no-wrap md:-mx-2 '>
                <div className='w-full'>
                  <div className='bg-white'>
                    <hr className='bg-gray-300 h-[5px]'></hr>
                    <form onSubmit={onSubmit}>
                      <div className='text-gray-500'>
                        <div className='grid grid-cols-[40%_60%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Password:
                            </div>
                          </div>
                          <TextInput
                            id='password'
                            name='password'
                            type='password'
                            placeholder='Password...'
                            onChange={handleChangeInput}
                            value={formData.password ?? ''}
                            sizing='md'
                            required
                          />
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[40%_60%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Confirm Password:
                            </div>
                          </div>
                          <TextInput
                            id='passwordConfirm'
                            name='passwordConfirm'
                            type='password'
                            placeholder='Confirm Password...'
                            onChange={handleChangeInput}
                            value={formData.passwordConfirm ?? ''}
                            sizing='md'
                            required
                          />
                        </div>
                      </div>
                      <div className='flex justify-end pt-2'>
                        <Button
                          disabled={buttonDisabled}
                          type='submit'
                          className='buttonMain'
                        >
                          Save
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
