import {
  Button,
  FileInput,
  Modal,
  Select,
  TextInput,
  Textarea,
} from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import UserApi from '../../Api/UserApi';
import { fetchFileTypes } from '../../Actions/CommonFields';
import { useAppDispatch, useAppSelector } from '../../Reducers/store';
import { File as UserFile } from '../../Models/File';
import CommonApi from '../../Api/CommonApi';

export default function AddFileModal({
  show,
  setShow,
  userId,
  memberId,
  setReload,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  memberId: number;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { fileTypes } = useAppSelector((state) => state.commonFields);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [fileUpload, setUploadFile] = useState<File | null>();
  const [formData, setFormData] = useState<UserFile>({
    id: 0,
    name: '',
    url: '',
    typeId: null,
    memberId: 0,
    userId: 0,
    description: '',
    typeName: '',
  });

  const getReferences = async () => {
    if (!fileTypes.length) {
      await Promise.all([dispatch(fetchFileTypes())]);
      //   setIsLoading(false);
    } else {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    getReferences();
  }, []);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      setUploadFile(event.currentTarget.files[0]);
    } else {
      setUploadFile(null);
    }
  }

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    try {
      const fd = new FormData();
      fd.append('file', fileUpload!);
      fd.append('name', formData.name!);
      fd.append('typeId', formData.typeId!.toString());
      fd.append('memberId', memberId.toString());
      fd.append('userId', userId.toString());
      fd.append('description', formData.description!);
      setIsLoading(true);
      return CommonApi.addFile(fd)
        .then((res) => {
          if (res.data.success) {
            toast.success('File Uploaded');
            setFormData({
              id: 0,
              name: '',
              url: '',
              typeId: null,
              memberId: 0,
              userId: 0,
              description: '',
              typeName: '',
            });
            setIsLoading(false);
            setButtonDisabled(false);
            setShow(false);
            setReload(true);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
            setButtonDisabled(false);
          }
        })
        .catch((err) => {
          toast.error('Error: ' + err);
          setIsLoading(false);
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };
  function onCloseModal() {
    setFormData({
      id: 0,
      name: '',
      url: '',
      typeId: null,
      memberId: 0,
      userId: 0,
      description: '',
      typeName: '',
    });
    setShow(false);
  }
  return (
    <Modal show={show} size='2xl' onClose={onCloseModal} popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className='w-full'>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>Add File</h1>
              </div>
            </div>
          </div>

          <div>
            <div className='container mx-auto p-2'>
              <div className='md:flex no-wrap md:-mx-2 '>
                {/* <!-- Right Side --> */}
                <div className='w-full'>
                  {/* <!-- Profile tab --> */}
                  {/* <!-- About Section --> */}
                  <div className='bg-white'>
                    <hr className='bg-gray-300 h-[5px]'></hr>
                    <form onSubmit={onSubmit}>
                      <div className='text-gray-500'>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              File Type:
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='w-full'>
                              <Select
                                id='typeId'
                                name='typeId'
                                onChange={handleChangeSelect}
                                value={formData.typeId ?? ''}
                                sizing='md'
                                required
                              >
                                <option value={''}>(Select Type)</option>
                                {fileTypes.map((ft) => (
                                  <option key={ft.id} value={ft.id ?? ''}>
                                    {ft.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Name:
                            </div>
                          </div>
                          <div className='w-full'>
                            <TextInput
                              id='name'
                              name='name'
                              type='text'
                              placeholder='File Name...'
                              onChange={handleChangeInput}
                              value={formData.name ?? ''}
                              sizing='md'
                            />
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              File:
                            </div>
                          </div>
                          <div className='w-full'>
                            <FileInput
                              id='file-upload'
                              onChange={handleFileChange}
                              disabled={buttonDisabled}
                              required
                            />
                          </div>
                        </div>
                        <hr className='bg-gray-300 h-[2px]'></hr>
                        <div className='grid grid-cols-[20%_80%] text-xl'>
                          <div className='flex'>
                            <div className='font-bold content-center'>
                              Description:
                            </div>
                          </div>
                          <div className='w-full'>
                            <TextInput
                              id='description'
                              name='description'
                              type='text'
                              placeholder='Description...'
                              onChange={handleChangeInput}
                              value={formData.description ?? ''}
                              sizing='md'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-end pt-2'>
                        <Button
                          disabled={buttonDisabled}
                          type='submit'
                          className='buttonMain'
                        >
                          Save
                        </Button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- End of profile tab --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
