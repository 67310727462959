import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { SearchRequest } from '../Models/SearchRequest';
import { SearchResult } from '../Models/SearchResult';

class SearchApi {
  static search(data: SearchRequest) {
    return axios.post<SearchResult[]>(
      `${API_URL}/api/search`,
      data,
      getAuthHeader()
    );
  }
}

export default SearchApi;
