export default function ThankYou() {
  return (
    <>
      <div className='w-full text-center pt-10'>
        Thank You for your Subscription, we will review the information and get
        back with you ASAP.
      </div>
    </>
  );
}
